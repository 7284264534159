import React, { useEffect, useState } from "react";
import "./Syllabus.scss";
import { useNavigate } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";
import { errorResponse, failResponse } from "config/config";

const PSIRPaper1 = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Initial mobile check
    const [content, setContent] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        setContent(`<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <style>
        .Eligibilityh3 {
            font-size: 18px;
            margin-bottom: 10px;
            text-align: center;
        }

        .Eligibilityp {
            margin-bottom: 8px;
            font-size: 15px;
            font-weight: 500;
            margin-top: 10px;
        }
        body{
        line-height:35px;
        }
        .title{
        color:#00239c;
        }
        .title1{
        color:#D2042D;
        }
        .pagetitle{
        color:#00239c;
        text-align: center;font-size: 26px;
        }
    </style>
</head>
<body>
    <h3 class="pagetitle">PSIR Syllabus Paper 2</h3>
    <strong class="title1">PAPER-II</strong>
    <div class="sub-section">
  <strong class="title">Paper 2 Section A - Comparative Political Analysis and International Politics</strong>
  <ol>
    <li><strong>Comparative Politics: Nature and major approaches; Political economy and political sociology perspectives; Limitations of the comparative method.</strong>
        <p>To explore comparative politics' methodologies, analyzing political systems through different frameworks. It covers political economy's focus on state-market relations, political sociology's emphasis on social-political interactions, and discusses limitations of comparing diverse political systems across cultures.</p>
    </li>
    <li><strong>State in Comparative Perspective: Characteristics and changing nature of the State in capitalist and socialist economies, and advanced industrial and developing societies.</strong>
        <p>To examine state characteristics across different economic systems - comparing interventionist socialist states with market-oriented capitalist ones. It analyzes how state roles differ between industrialized and developing nations, focusing on economic management, welfare policies, and state-society relationships.</p>
    </li>
    <li><strong>Politics of Representation and Participation: Political parties, pressure groups and social movements in advanced industrial and developing societies.</strong>
        <p>To examine how citizens engage in democracy through voting, political parties, social movements, and interest groups. Covers electoral systems, democratic participation, political mobilization, civil society and barriers to representation.</p>
    </li>
    <li><strong>Globalisation: Responses from developed and developing societies.</strong>
        <p>To explore economic, cultural, and political integration worldwide. Developed nations focus on maintaining competitiveness while developing countries balance growth with sovereignty.</p>
    </li>
    <li><strong>Approaches to the Study of International Relations: Idealist, Realist, Marxist, Functionalist and Systems theory.</strong>
        <p>International Relations employs various approaches to understand global politics like that of the Traditional approaches focus on diplomatic history and international law, while contemporary methods include behavioural, rational choice, and constructivist theories. Each offers unique insights into international interactions and decision-making processes.</p>
    </li>
    <li><strong>Key Concepts in International Relations: National interest, security and power; Balance of power and deterrence; Transnational actors and collective security; World capitalist economy and globalisation.</strong>
        <p>To analyse the foundational ideas that shape understanding of state behaviour, conflict, cooperation, and global governance in world politics.</p>
    </li>
    <li><strong>Changing International Political Order:</strong>
        <ul>
            <li><strong>Rise of superpowers; Strategic and ideological Bipolarity, arms race and cold war; Nuclear threat.</strong>
                <p>To trace the emergence of the two superpowers post-WWII that divided the world ideologically. Their nuclear arms race defined the Cold War era's tensions.</p>
            </li>
            <li><strong>Non-aligned movement: Aims and achievements.</strong>
                <p>The NAM topic covers Third World nations' efforts to remain independent during Cold War, focusing on Bandung principles, leadership of Nehru-Nasser-Tito, anti-colonialism stance, and its impact on international relations.</p>
            </li>
            <li><strong>Collapse of the Soviet Union; Unipolarity and American hegemony; Relevance of non-alignment in the contemporary world.</strong>
                <p>The topic examines USSR's dissolution in 1991, resulting in US dominance as sole superpower, emergence of unipolar world order, American global influence, and debates over NAM's continued relevance in post-Cold War era.</p>
            </li>
        </ul>
    </li>
    <li><strong>Evolution of the International Economic System: From Bretton woods to WTO; Socialist economies and the CMEA (Council for Mutual Economic Assistance); Third World demand for new international economic order; Globalisation of the world economy.</strong>
        <p>This covers post-WW2 economic institutions (IMF, World Bank), shift to WTO system, socialist economic cooperation through CMEA, developing nations' push for fairer economic order, and globalization's impact through trade liberalization, market integration, and economic interdependence.</p>
    </li>
    <li><strong>United Nations: Envisaged role and actual record; Specialized UN agencies—aims and functioning; the need for UN reforms.</strong>
        <p>The topic explores UN's founding objectives versus its actual performance, examining roles of specialized agencies (WHO, UNESCO, UNICEF), peacekeeping missions, development programs, and debates on reforming Security Council structure, veto power, and organizational effectiveness for contemporary challenges.</p>
    </li>
    <li><strong>Regionalisation of World Politics: EU, ASEAN, APEC, SAARC, NAFTA.</strong>
        <p>This examines regional organizations' evolution, focusing on European Union's integration model, ASEAN's cooperative framework, APEC's economic coordination, SAARC's South Asian initiatives, and NAFTA's trade partnership, analyzing their roles in promoting regional cooperation and global governance.</p>
    </li>
    <li><strong>Contemporary Global Concerns: Democracy, human rights, environment, gender justice, terrorism, nuclear proliferation.</strong>
        <p>This topic covers major global challenges: promoting democratic values worldwide, protecting human rights, addressing climate change and environmental degradation, advancing gender equality, combating international terrorism, and managing nuclear weapons proliferation through international cooperation and treaties.</p>
    </li>
</ol>


  <strong class="title">India and the World</strong>
    <ol>
    <li><strong>Indian Foreign Policy:</strong>
        <ul>
            <li><strong>Determinants of Foreign Policy:</strong> Geography, ideology, strategic interests, and policy-making institutions like the Prime Minister's Office and Ministry of External Affairs. Analyzing the shift from non-alignment to multi-alignment, focusing on continuity and change in India’s international engagement.</li>
        </ul>
    </li>

    <li><strong>India’s Contribution to the Non-Alignment Movement:</strong>
        <ul>
            <li><strong>Different Phases and Current Role:</strong> India's leadership under Nehru, evolution from Cold War origins to contemporary geopolitical relevance, analyzing strategic shifts, diplomatic contributions, and the movement's transformation in a multipolar global context.</li>
        </ul>
    </li>

    <li><strong>India and South Asia:</strong>
        <ul>
            <li><strong>Regional Cooperation – SAARC:</strong> Past performance and future prospects. Analyzing historical limitations, bilateral tensions, economic potential, and geopolitical constraints for regional integration.</li>
            <li><strong>South Asia as a Free Trade Area:</strong> Challenges in trade integration, economic potential, barriers to cooperation, intra-regional trade dynamics, and the prospects of a comprehensive free trade framework.</li>
            <li><strong>India’s “Look East” Policy:</strong> Examining the policy evolution, strategic engagement with Southeast Asia, economic partnerships, geopolitical significance, and transformation into the "Act East" approach.</li>
            <li><strong>Impediments to Regional Cooperation:</strong> River water disputes, illegal cross-border migration, ethnic conflicts, insurgencies, and unresolved border disputes that hinder regional integration and development.</li>
        </ul>
    </li>

    <li><strong>India and the Global South:</strong>
        <ul>
            <li><strong>Relations with Africa and Latin America:</strong> Strategic partnerships, leadership in NIEO reforms, and representation of developing nations in WTO negotiations and multilateral economic discussions.</li>
        </ul>
    </li>

    <li><strong>India and the Global Centres of Power:</strong>
        <ul>
            <li><strong>Relations with USA, EU, Japan, China, and Russia:</strong> Strategic diplomacy, economic partnerships, geopolitical challenges, and balancing strategic autonomy in a multipolar world.</li>
        </ul>
    </li>

    <li><strong>India and the UN System:</strong>
        <ul>
            <li><strong>Role in UN Peacekeeping:</strong> Contributions to peacekeeping missions and strategic diplomatic efforts for a permanent Security Council seat.</li>
            <li><strong>UN Reforms:</strong> Advocacy for structural representation and decision-making reforms in the UN.</li>
        </ul>
    </li>

    <li><strong>India and the Nuclear Question:</strong>
        <ul>
            <li><strong>Changing Perceptions and Policy:</strong> India's nuclear policy trajectory from isolation to strategic recognition, technological advancements, geopolitical challenges, and maintaining a credible minimum deterrence.</li>
        </ul>
    </li>

    <li><strong>Recent Developments in Indian Foreign Policy:</strong>
        <ul>
            <li><strong>India’s Position on Recent Global Crises:</strong> Afghanistan’s political instability, Iraq’s reconstruction, Middle East tensions.</li>
            <li><strong>Growing Relations with the US and Israel:</strong> Deepening strategic partnerships, diplomatic shifts, and economic cooperation.</li>
            <li><strong>Vision for a New World Order:</strong> India’s role in shaping a multipolar global landscape.</li>
        </ul>
    </li>
  </ol>
</div>


      
    
    </body>
</html>
`);
    });

    useEffect(() => {
        // Function to fetch the content
        const fetchUPSCcontent = async () => {
            try {
                // const res = await GetUPSCcontent();
                // if (res.code === 200) {
                //   const { htmlcontent } = res.data.content[3];
                //   setContent(htmlcontent);
                // } else {
                //   failResponse(res);
                // }
            } catch (err) {
                errorResponse(err);
            }
        };

        // Function to update isMobile state on window resize
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        fetchUPSCcontent(); // Fetch content on component mount

        window.addEventListener("resize", handleResize); // Listen for window resize
        return () => {
            window.removeEventListener("resize", handleResize); // Clean up listener on unmount
        };
    }, []); // Empty dependency array ensures this runs only once (on mount)

    // Handle back button functionality
    const handleBack = () => {
        navigate("/freeresources/FRMains"); // Use navigate to go back to the specified route
    };

    return (
        <div className="syllabus-container">
            {isMobile && (
                <div className="mobile-back-arrow" onClick={handleBack}>
                    <FaChevronLeft style={{ color: "#181822", fontSize: "24px" }} />{" "}
                    {/* Font Awesome Arrow Icon */}
                </div>
            )}
            <div className="subsection-prelims-syllabus-container">
                <div dangerouslySetInnerHTML={{ __html: content }} />
            </div>
        </div>
    );
};

export default PSIRPaper1;
