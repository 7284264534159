import React, { useState } from "react";
import { Tab } from "react-bootstrap";
import "./TestPage.scss";
import { FaQuestionCircle, FaRegCalendarAlt, FaRegClock } from "react-icons/fa";
import { TestpageImg } from "assets/images";
import { AiOutlineDownload, AiOutlineFileText } from "react-icons/ai";
import { MdOutlineSmartDisplay } from "react-icons/md";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { toast } from "react-hot-toast";
import { userAttemptMainsTest, userTestSeriesDetail } from "services/test";
import { useCourse } from "context/context";
import {
  countArray,
  momentDateFormat,
  testAccess,
  testAccessWithStartDate,
  testIsExpired,
  testIsStarted,
} from "config/utils";
import ListCourseSkeleton from "components/skeleton/ListCourseSkeleton";
import { FiDownload, FiUpload } from "react-icons/fi";
import SuperTag from "components/SuperTag/SuperTag";
import CommonModal from "components/modal-box/CommonModal";
import YoutubeVideo from "components/video-player/YoutubeVideo";
import VideoPlayModal from "components/video-play-modal/VideoPlayModal";
import useButtonLoader from "hooks/useButtonLoader";
import { encryptData, errorResponse, failResponse } from "config/config";
import useCookie from "hooks/useCookie";
import VideoCipherPlayer from "components/video-player/VdoCipherPlayer";
import { vdoCipherGenerateOtp } from "services/lecture";
import { VideoPlayIconImg } from "assets/icons";
import { getUserIp } from "services/third-party";
import FileUploader from "components/FileUploader/FileUploader";
import PercentageLoader from "Loader/PercentageLoader";
import { getSignedUrl } from "services/master";

function TestPage() {
  const fileTypes = ["PDF"];

  const { course } = useCourse();
  const { testId } = useParams();
  const [loading, setLoading] = useState(true);
  const [testData, setTestData] = useState({});
  const [testDetails, setTestDetails] = useState({});
  const [answerSheet, setAnswerSheet] = useState("");

  const [allVideos, setAllVideos] = useState([]);
  const [allNotes, setAllNotes] = useState([]);
  const location = useLocation();

  const [fileUploadFlag, setFileUploadFlag] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(0);

  const [videoModal, setVideoModal] = useState(false);
  const [noteModal, setNoteModal] = useState(false);
  const [uploadPaperModal, setUploadPaperModal] = useState(false);

  const [playVideoModal, setPlayVideoModal] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState("");
  const [selectedVideoTitle, setSelectedVideoTitle] = useState("");

  const [videoType, setVideoType] = useState("");
  const [cipherLoading, setCipherLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [playbackInfo, setPlaybackInfo] = useState("");

  const [selectedSyllabus, setSelectedSyllabus] = useState("");
  const [syllabusModal, setSyllabusModal] = useState(false);

  const [answerSubmitLoading, setAnswerSubmitLoading] = useState(false);
  const [paperSubmitLoading, setPaperSubmitLoading] = useState(false);

  const [selectedNote, setSelectedNote] = useState("");

  const [submitButtonRef, setSubmitLoading] = useButtonLoader("Upload");
  const { cookies } = useCookie();
  const navigate = useNavigate();

  // view notes modal function
  const viewNotesModalHandler = () => {
    setNoteModal(true);
  };

  // view videos modal function
  const viewVideosModalHandler = () => {
    setVideoModal(true);
  };

  // generate otp of vdocipher
  const videoPlayHandler = async (file, videoTypeParam, title) => {
    setSelectedVideo(file);
    setSelectedVideoTitle(title);
    setVideoType(videoTypeParam);
    if (+videoTypeParam === 2) {
      try {
        setCipherLoading(true);
        const { data: ip } = await getUserIp();
        const res = await vdoCipherGenerateOtp({
          video_id: file,
          IPAddress: ip.ip,
        });

        if (res.code === 200) {
          setOtp(res.data.otp || "");
          setPlaybackInfo(res.data.playbackInfo || "");
          setPlayVideoModal(true);
          setCipherLoading(false);
        } else {
          failResponse(res);
          setCipherLoading(false);
        }
      } catch (err) {
        errorResponse(err);
        setCipherLoading(false);
      }
    }

    if (+videoTypeParam === 1) {
      setPlayVideoModal(true);
    }
  };

  // this condition is used for test type condition
  const testTypeCondition = (type, subType) => {
    if (
      (+type === 3 && subType === "MCQ") ||
      (+type === 4 && subType === "Prelims")
    ) {
      return true;
    } else {
      return false;
    }
  };

  // upload answer sheet function
  const uploadSheetHandler = async () => {
    if (!answerSheet) toast.error("Please select answer sheet");
    else {
      try {
        setSubmitLoading(true);
        setFileUploadFlag(true);
        const formData = new FormData();
        formData.append("UploadedAnswerSheet", answerSheet);
        formData.append(
          "reqData",
          encryptData({
            SectionTestID: testId,
            CourseID: course.courseId,
            access_token: cookies,
          })
        );
        const options = {
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total);
            setUploadPercentage(percent);
          },
          headers: {
            Authorization: "Bearer " + cookies,
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        };
        const res = await userAttemptMainsTest(formData, options);

        if (res.code === 200) {
          setSubmitLoading(false);
          setUploadPaperModal(false);
          setFileUploadFlag(false);
          toast.success(res.message);
        } else {
          failResponse(res);
          setSubmitLoading(false);
          setFileUploadFlag(false);
        }
      } catch (err) {
        errorResponse(err);
        setSubmitLoading(false);
        setFileUploadFlag(false);
      }
    }
  };

  // navigation handler
  const navigationHandler = (
    id,
    startDate,
    endDate,
    totalQuestions,
    totalTestQuestion
  ) => {
    if (+totalQuestions >= +totalTestQuestion) {
      if (testIsStarted(startDate)) {
        toast.error("Test is not started");
        return;
      }
      if (testIsExpired(endDate)) {
        toast.error("Test is expired");
        return;
      }
      if (testAccess(startDate, endDate)) {
        navigate(`/dashboard/test-series/detail/${id}/test`, {
          state: { auth: true },
        });
      }
    } else {
      toast.error("currently, there are no questions available in this test");
    }
  };

  // result handler
  const resultHandler = (id, startDate, endDate, result) => {
    if (testIsStarted(startDate)) {
      toast.error("Test is not started");
      return;
    }
    if (testIsExpired(endDate)) {
      toast.error("Test is expired");
      return;
    }
    if (testAccess(startDate, endDate)) {
      if (result) {
        navigate(`/dashboard/test-series/detail/${id}/test-overview`, {
          state: { auth: true },
        });
      } else {
        toast.error("result is not generated");
      }
    }
  };

  // notes download handler
  const notesDownloadHandler = (notes, startDate, endDate) => {
    viewNotesModalHandler(notes);
  };

  // videos download handler
  const viewVideosHandler = (videos, startDate, endDate) => {
    if (testAccess(startDate, endDate)) {
      viewVideosModalHandler(videos);
    } else {
      toast.error("you can't play videos");
    }
  };

  // syllabus download handler
  const syllabusDownloadHandler = (syllabus) => {
    setSelectedSyllabus(syllabus);
    setSyllabusModal(true);
  };

  // answer sheet download
  const modalAnswerSheetDownloadHandler = async (sheet, startDate) => {
    if (testAccessWithStartDate(startDate)) {
      setAnswerSubmitLoading(true);
      window.open(await getSignedUrl({ pdfUrl: sheet }));
      setAnswerSubmitLoading(false);
    } else {
      toast.error("you can't download model answer sheet");
    }
  };
  // test pdf download handler
  const paperPDFDownloadHandler = async (paper, startDate, endDate) => {
    if (testAccess(startDate, endDate)) {
      setPaperSubmitLoading(true);
      window.open(await getSignedUrl({ pdfUrl: paper }));
      setPaperSubmitLoading(false);
    } else {
      toast.error("you can't download question sheet");
    }
  };

  // notes download handler
  const singleNotesDownloadHandler = async (note) => {
    setSelectedNote(note);
    window.open(await getSignedUrl({ pdfUrl: note }));
    setSelectedNote("");
  };

  // upload answer handler
  const uploadAnswerHandler = (startDate, endDate) => {
    if (testAccess(startDate, endDate)) {
      setUploadPaperModal(true);
    } else {
      toast.error("you can't upload answer sheet");
    }
  };

  // ----------------------- getting test detail data -------------------------//
  React.useLayoutEffect(() => {
    // get test series detail
    const getTestDetails = async () => {
      try {
        setLoading(true);
        const res = await userTestSeriesDetail({
          courseId: course.courseId,
          testId,
        });

        if (res.code === 200) {
          setTestDetails(res.data.testDetails || {});
          setTestData(res.data || {});
          setAllNotes(res.data.testDetails.notes || []);
          setAllVideos(res.data.testDetails.videos || []);
          setLoading(false);
        } else {
          failResponse(res);
          setLoading(false);
        }
      } catch (err) {
        errorResponse(err);
        setLoading(false);
      }
    };

    if (course.courseId) {
      getTestDetails();
    }
    // eslint-disable-next-line
  }, [course]);

  return (
    <>
      {!location?.state?.auth ? (
        <Navigate to="/dashboard/test-series" />
      ) : (
        <div className="testPage">
          <div className="container">
            <div className="dashboard_tab">
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey="Test_series"
              >
                <Tab.Content className="">
                  <Tab.Pane eventKey="Test_series">
                    {/************** loading components **************/}
                    {loading && <ListCourseSkeleton height="300" />}

                    {/************** main components **************/}
                    {!loading && (
                      <div className="testPage_banner">
                        <div className="image_wrap">
                          <img src={TestpageImg} alt="" loading="lazy" />
                        </div>
                        <div className="content">
                          <h4>{testDetails.TestTitle}</h4>
                          <ul>
                            <li>
                              <FaRegCalendarAlt />
                              {momentDateFormat(testData.scheduleDate)}
                              {/* at 7:30AM */}
                            </li>
                            {/* // */}
                            <li>
                              <FaQuestionCircle /> {testDetails.TotalQuestions}
                              Q’s *{testDetails.TotalMarks} marks
                            </li>
                            <li>
                              <FaRegClock /> {testDetails.TestDuration} mins
                            </li>
                          </ul>

                          {/* Prelims access buttons */}
                          {testTypeCondition(
                            testDetails.TestType,
                            testDetails.TestSubType
                          ) && (
                            <div className="button_wrap">
                              <button
                                className="dashboard_button red fill"
                                onClick={() =>
                                  navigationHandler(
                                    testId,
                                    testData?.scheduleDate,
                                    testData?.ValidDate,
                                    testData?.questionCount,
                                    testDetails?.TotalQuestions
                                  )
                                }
                                disabled={
                                  !testAccess(
                                    testData.scheduleDate,
                                    testData.ValidDate
                                  )
                                }
                              >
                                {`${
                                  testData.mcqResult ? "Retake" : "Take"
                                } test`}
                              </button>

                              <button
                                type="button"
                                className="dashboard_button blue fill"
                                onClick={() =>
                                  resultHandler(
                                    testId,
                                    testData?.scheduleDate,
                                    testData?.ValidDate,
                                    testData?.mcqResult
                                  )
                                }
                                disabled={
                                  !testAccess(
                                    testData.scheduleDate,
                                    testData.ValidDate
                                  ) || !testData.mcqResult
                                }
                              >
                                View result
                              </button>

                              <button
                                type="button"
                                className="dashboard_button"
                                disabled={
                                  !testAccess(
                                    testData.scheduleDate,
                                    testData.ValidDate
                                  ) || allNotes.length <= 0
                                }
                                onClick={() =>
                                  notesDownloadHandler(
                                    allNotes,
                                    testData?.scheduleDate,
                                    testData?.ValidDate
                                  )
                                }
                              >
                                <FiDownload /> Notes
                              </button>
                              <button
                                type="button"
                                className="dashboard_button"
                                disabled={
                                  !testAccess(
                                    testData.scheduleDate,
                                    testData.ValidDate
                                  ) || !testDetails.SyllabusPDF
                                }
                                onClick={() =>
                                  syllabusDownloadHandler(
                                    testDetails.SyllabusPDF,
                                    testData.scheduleDate,
                                    testData.ValidDate
                                  )
                                }
                              >
                                <AiOutlineFileText /> Syllabus
                              </button>
                              <button
                                type="button"
                                className="dashboard_button"
                                disabled={
                                  !testAccessWithStartDate(
                                    testData.videoAccessDate
                                  ) || allVideos?.length <= 0
                                }
                                onClick={() => {
                                  viewVideosHandler(
                                    allVideos,
                                    testData.videoAccessDate
                                  );
                                }}
                              >
                                <MdOutlineSmartDisplay /> Video
                              </button>
                            </div>
                          )}

                          {/* Mains access buttons */}
                          {!testTypeCondition(
                            testDetails.TestType,
                            testDetails.TestSubType
                          ) && (
                            <div className="button_wrap">
                              <button
                                to="test"
                                type="button"
                                className="dashboard_button red fill"
                                onClick={() =>
                                  paperPDFDownloadHandler(
                                    testDetails.TestPaperPDF,
                                    testData.scheduleDate,
                                    testData.ValidDate
                                  )
                                }
                                disabled={
                                  !testAccess(
                                    testData.scheduleDate,
                                    testData.ValidDate
                                  ) ||
                                  !testDetails.TestPaperPDF ||
                                  paperSubmitLoading
                                }
                              >
                                <FiDownload /> <div>Question paper</div>
                              </button>

                              <button
                                type="button"
                                className="dashboard_button blue fill"
                                onClick={() => {
                                  uploadAnswerHandler(
                                    testData.scheduleDate,
                                    testData.ValidDate
                                  );
                                }}
                                disabled={
                                  !testAccess(
                                    testData.scheduleDate,
                                    testData.ValidDate
                                  ) || !testDetails.TestPaperPDF
                                }
                              >
                                <FiUpload /> <div>Upload paper</div>
                              </button>
                              <button
                                type="button"
                                className="dashboard_button blue"
                                disabled
                                // onClick={() =>
                                //   window.open(
                                //     subjectiveResult?.EvaluatedAnswerSheet
                                //   )
                                // }
                                // disabled={
                                //   subjectiveResult?.EvaluationStatus !==
                                // "Evaluated" &&
                                // !subjectiveResult?.EvaluatedAnswerSheet
                                // }
                              >
                                <FiDownload /> <div>Evaluated Sheet</div>
                              </button>
                              <button
                                type="button"
                                className="dashboard_button blue"
                                onClick={() =>
                                  modalAnswerSheetDownloadHandler(
                                    testDetails.ModelAnswerSheetPDF,
                                    testData.scheduleDate
                                  )
                                }
                                disabled={
                                  !testAccessWithStartDate(
                                    testData.scheduleDate
                                  ) ||
                                  !testDetails.ModelAnswerSheetPDF ||
                                  answerSubmitLoading
                                }
                              >
                                <FiDownload /> <div>Model Answerr</div>
                              </button>
                              <button
                                type="button"
                                className="dashboard_button blue"
                                onClick={() =>
                                  modalAnswerSheetDownloadHandler(
                                    testDetails.subjectiveResult
                                      ?.UploadedAnswerSheet,
                                    testData.scheduleDate
                                  )
                                }
                                disabled={
                                  !testAccessWithStartDate(
                                    testData.scheduleDate
                                  ) ||
                                  !testDetails.subjectiveResult
                                    ?.UploadedAnswerSheet ||
                                  answerSubmitLoading
                                }
                              >
                                <FiDownload /> <div>Answer Sheet</div>
                              </button>
                              <button
                                type="button"
                                className="dashboard_button blue"
                                disabled={
                                  !testAccess(
                                    testData.scheduleDate,
                                    testData.ValidDate
                                  ) || allNotes.length <= 0
                                }
                                onClick={() =>
                                  notesDownloadHandler(
                                    allNotes,
                                    testData.scheduleDate,
                                    testData.ValidDate
                                  )
                                }
                              >
                                <FiDownload /> <div>Notes</div>
                              </button>
                              <button
                                type="button"
                                className="dashboard_button blue"
                                disabled={
                                  !testAccess(
                                    testData.scheduleDate,
                                    testData.ValidDate
                                  ) || !testDetails.SyllabusPDF
                                }
                                onClick={() =>
                                  syllabusDownloadHandler(
                                    testDetails.SyllabusPDF,
                                    testData.scheduleDate,
                                    testData.ValidDate
                                  )
                                }
                              >
                                <AiOutlineFileText /> <div>Syllabus</div>
                              </button>
                              <button
                                type="button"
                                className="dashboard_button blue"
                                disabled={
                                  !testAccessWithStartDate(
                                    testData.videoAccessDate
                                  ) || allVideos?.length <= 0
                                }
                                onClick={() => {
                                  viewVideosHandler(
                                    allVideos,
                                    testData.videoAccessDate
                                  );
                                }}
                              >
                                <MdOutlineSmartDisplay /> <div>Play video</div>
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    <div className="testPage_content spacing">
                      <div className="instruction">
                        <h4>Instructions</h4>

                        {/************** loading components **************/}
                        {loading &&
                          countArray(5).map((data) => {
                            return (
                              <ListCourseSkeleton
                                height="20"
                                key={data}
                                className="my-2"
                              />
                            );
                          })}

                        {/************** main components **************/}
                        {!loading && <SuperTag>{testDetails.Cfield1}</SuperTag>}

                        <h5>All the best!</h5>

                        {/* <h5 className="red">Note</h5>
                    <p className="notice">
                      Ranking is based on marks. If learners have the same
                      marks, the time taken to answer the test is used to break
                      the tie.
                    </p> */}
                      </div>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </div>

          {/* Video modal */}
          <CommonModal
            show={videoModal}
            handleClose={() => setVideoModal(false)}
            className={"modal-lg"}
            title={"Videos Notes"}
          >
            <div className="course_listing">
              <ul>
                {allVideos &&
                  allVideos.map(({ videoDeatils }, i) => {
                    return (
                      <li key={i}>
                        <span className="sr_number">{++i}.</span>
                        <p>
                          {videoDeatils?.Videotitle} ({videoDeatils?.targetyear}
                          )
                        </p>
                        {/* video play button */}
                        {videoDeatils?.Videonumber === selectedVideo ? (
                          cipherLoading ? (
                            <button type="button" className="button">
                              <div
                                class="spinner-border spinner-border-sm text-light"
                                role="status"
                              >
                                <span class="sr-only"></span>
                              </div>
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="button"
                              onClick={() => {
                                videoPlayHandler(
                                  videoDeatils?.Videonumber,
                                  videoDeatils?.type,
                                  `${videoDeatils?.Videotitle} (${videoDeatils?.targetyear})`
                                );
                              }}
                              disabled={!videoDeatils?.Videonumber}
                            >
                              <img
                                src={VideoPlayIconImg}
                                alt=""
                                loading="lazy"
                              />
                            </button>
                          )
                        ) : (
                          <button
                            type="button"
                            className="button"
                            onClick={() => {
                              videoPlayHandler(
                                videoDeatils?.Videonumber,
                                videoDeatils?.type,
                                `${videoDeatils?.Videotitle} (${videoDeatils?.targetyear})`
                              );
                            }}
                            disabled={!videoDeatils?.Videonumber}
                          >
                            <img src={VideoPlayIconImg} alt="" loading="lazy" />
                          </button>
                        )}
                      </li>
                    );
                  })}
              </ul>
            </div>
          </CommonModal>

          {/* Note modal */}
          <CommonModal
            show={noteModal}
            className={"modal-lg"}
            handleClose={() => setNoteModal(false)}
            title={"Notes"}
          >
            <div className="course_listing">
              <ul>
                {allNotes &&
                  allNotes.map(({ noteDeatils }, i) => {
                    return (
                      <li key={i}>
                        <span className="sr_number">{++i}.</span>
                        <p>
                          {noteDeatils?.Vnotetitle} ({noteDeatils?.Vtargetyear})
                        </p>
                        <button
                          type="button"
                          className="button"
                          onClick={() =>
                            singleNotesDownloadHandler(noteDeatils?.VnoteFile)
                          }
                          disabled={
                            !noteDeatils?.VnoteFile ||
                            selectedNote === noteDeatils?.VnoteFile
                          }
                        >
                          <AiOutlineDownload />
                        </button>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </CommonModal>

          {/* Youtube Video Player Modal */}
          <VideoPlayModal
            modalShow={playVideoModal}
            modalHide={() => setPlayVideoModal(false)}
          >
            <h5 className="text-center text-white my-2 mb-0">
              {selectedVideoTitle}
            </h5>
            {/* Youtube Player */}
            {+videoType === 1 && selectedVideo && (
              <>
                <YoutubeVideo src={selectedVideo} width="100%" height="400" />
              </>
            )}

            {/* Vdocipher Player */}
            {+videoType === 2 && selectedVideo && (
              <>
                {playbackInfo && otp && (
                  <VideoCipherPlayer otp={otp} playbackInfo={playbackInfo} />
                )}
              </>
            )}
          </VideoPlayModal>

          {/* upload paper modal */}
          <CommonModal
            show={uploadPaperModal}
            className={"modal-lg reupload_loader"}
            handleClose={() => {
              if (!fileUploadFlag) {
                setUploadPaperModal(false);
                setAnswerSheet("");
              }
            }}
            title={"Upload Answer Sheet"}
          >
            <div className="testPage_upload_paper">
              <FileUploader
                onChange={(file) => setAnswerSheet(file)}
                file={answerSheet}
                types={fileTypes}
                classes={`px-3 h-50 test_upload file_uploader ${
                  fileUploadFlag && "disabled"
                }`}
                message={"File selected,click on upload to save it"}
              />
              {fileUploadFlag && (
                <PercentageLoader percentage={uploadPercentage} />
              )}
              <div className="upload_paper__footer text-center my-3">
                <button
                  className="button"
                  onClick={uploadSheetHandler}
                  ref={submitButtonRef}
                >
                  Upload
                </button>
              </div>
            </div>
          </CommonModal>

          {/* syllabus modal */}
          <CommonModal
            show={syllabusModal}
            handleClose={() => setSyllabusModal(false)}
            size="lg"
            title={"Syllabus"}
          >
            <div className="terms_condition">
              <div className="terms_condition_content">
                <SuperTag>{selectedSyllabus}</SuperTag>
              </div>
            </div>
          </CommonModal>
        </div>
      )}
    </>
  );
}

export default TestPage;
