import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./GS3Notes.scss";
import { useNavigate } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";
import { GetGSSubjects, GetGSSubjectChapters, GetGSSelectedChapter } from "services/freeresources/freeresources";
import { errorResponse, failResponse } from "config/config";
import NoSlotsMessage from "dashboard/mentor-book-appointment/NoSlotsMessage";

const GS1Notes = () => {
    const location = useLocation();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [selectedTable, setselectedTable] = useState("subject");
    const [selectedSubject, setselectedSubject] = useState("subject");
    const [subjects, setSubjects] = useState([]);
    const [subjectChapters, setsubjectChapters] = useState([]);
    const [ChapterContent, setSelectedChapterContent] = useState();
    const [Chapter, setSelectedChapter] = useState();
    const [subjectId, setsubjectId] = useState();
    const [sectionId, setsectionId] = useState(3);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        const fetchGSSubjects = async () => {
            try {
                const res = await GetGSSubjects({ sectionid: sectionId });
                if (res.code === 200) {
                    setSubjects(res.data.gsSubjects); // Set subjects from API
                } else {
                    failResponse(res);
                }
            } catch (err) {
                errorResponse(err);
            }
        };
        fetchGSSubjects();
        console.log("Route updated:", location.pathname);
        if (location.pathname === "/freeresources/FRMainsGS3Notes") {
            setselectedTable("subject");
        }
    }, [location]);

    const handleBack = () => {
        navigate("/freeresources/FRMains");
    };

    const handleButtonClick = async (subject, subjectid, sectionid) => {
        setselectedTable("chapters");
        setselectedSubject(subject);
        setsectionId(sectionid);
        setsubjectId(subjectid);
        setIsLoading(true);  // Set loading state to true before fetching

        try {
            const res = await GetGSSubjectChapters({ sectionid: sectionid, subjectid: subjectid });
            if (res.code === 200) {
                setsubjectChapters(res.data.gsSubjectChapters);
            } else {
                failResponse(res);
            }
        } catch (err) {
            errorResponse(err);
        } finally {
            setIsLoading(false);  // Set loading state to false after fetching
        }
    };
    const handleSelectedChapter = async (chaptername, subjectid, sectionid, chapterid) => {
        setselectedTable("chaptercontent");
        setselectedSubject(selectedSubject);
        setsectionId(sectionid);
        setsubjectId(subjectid);

        try {
            const res = await GetGSSelectedChapter({ sectionid: sectionid, subjectid: subjectid, chapterid: chapterid });
            if (res.code === 200) {
                setSelectedChapterContent(res.data.gsSubjectChapter.chaptercontent);
                setSelectedChapter(res.data.gsSubjectChapter.chaptername);
            } else {
                failResponse(res);
            }
        } catch (err) {
            errorResponse(err);
        }
    };

    return (
        <div className="gs1notes-container">
            {isMobile && (
                <div className="mobile-back-arrow" onClick={handleBack}>
                    <FaChevronLeft style={{ color: "#181822", fontSize: "24px" }} />
                </div>
            )}
            <div className="gs1notes">
                <div className="breadcrumbs">
                    <span onClick={() => navigate("/freeresources/FRMainsGS3Overview")} className="gs1">GS III</span>
                    <span className="separator">{">"}</span>

                    {/* GS I Notes */}
                    <span
                        className={`active ${selectedTable === "chapters" || selectedTable === "chaptercontent" ? "blue-text pointer" : "default-cursor"}`}
                        onClick={() => setselectedTable("subject")}
                    >
                        GS III Notes
                    </span>

                    {(selectedTable === "chapters" || selectedTable === "chaptercontent") && (
                        <>
                            <span className="separator">{">"}</span>

                            {/* Selected Subject */}
                            <span
                                className={`active ${selectedTable === "chaptercontent" ? "blue-text pointer" : "default-cursor"}`}
                                onClick={() => setselectedTable("chapters")}
                            >
                                {selectedSubject}
                            </span>

                            {selectedTable === "chaptercontent" && (
                                <>
                                    <span className="separator">{">"}</span>

                                    {/* Selected Chapter */}
                                    <span className="active default-cursor">
                                        {Chapter}
                                    </span>
                                </>
                            )}
                        </>
                    )}
                </div>


                <div className="table-wrapper">
                    {selectedTable === "subject" && (
                        <table className="styled-table">
                            <tbody>
                                {subjects.length > 0 ? (
                                    subjects.reduce((rows, subject, index) => {
                                        if (index % 4 === 0) rows.push([]);
                                        rows[rows.length - 1].push(subject);
                                        return rows;
                                    }, []).map((row, rowIndex) => (
                                        <tr key={rowIndex}>
                                            {row.map((subject, colIndex) => (
                                                <td key={colIndex}>
                                                    <button
                                                        onClick={() => handleButtonClick(subject.subjectname, subject.subjectid, subject.sectionid)}
                                                        className={`subject-button subject-count-${subjects.length}`}
                                                    >
                                                        {subject.subjectname}
                                                    </button>
                                                </td>
                                            ))}
                                            {row.length < 4 && [...Array(4 - row.length)].map((_, i) => <td key={`empty-${i}`}></td>)}
                                        </tr>
                                    ))
                                ) : (
                                    <NoSlotsMessage message={`No Subjects Available`} />
                                )}
                            </tbody>
                        </table>
                    )}
                    {selectedTable === "chapters" && (
                        <table className="styled-table">
                            <tbody>
                                {isLoading ? (  // Show nothing while loading
                                    <tr><td>Loading...</td></tr>
                                ) : subjectChapters.length > 0 ? (
                                    subjectChapters.reduce((rows, chapter, index) => {
                                        if (index % 2 === 0) rows.push([]);
                                        rows[rows.length - 1].push(chapter);
                                        return rows;
                                    }, []).map((row, rowIndex) => (
                                        <tr key={rowIndex}>
                                            {row.map((chapter, colIndex) => (
                                                <td key={colIndex}>
                                                    <button
                                                        className={`subject-chapter-button subject-count-${subjectChapters.length}`}
                                                        onClick={() => handleSelectedChapter(chapter.chaptername, chapter.subjectid, chapter.sectionid, chapter.chapterid)}
                                                    >
                                                        <div>
                                                            <span>Chapter no. {rowIndex * 2 + colIndex + 1}</span>
                                                        </div>
                                                        <div>{chapter.chaptername}</div>
                                                    </button>
                                                </td>
                                            ))}
                                            {row.length < 2 && [...Array(2 - row.length)].map((_, i) => <td key={`empty-${i}`}></td>)}
                                        </tr>
                                    ))
                                ) : (
                                    !isLoading && <NoSlotsMessage message={`No Chapters Available for selected subject`} />
                                )}
                            </tbody>
                        </table>
                    )}
                    {selectedTable === "chaptercontent" && (
                        <div className="chapter-content-container">
                            {ChapterContent ? (
                                <div dangerouslySetInnerHTML={{ __html: ChapterContent }} />
                            ) : (
                                <NoSlotsMessage message="No Content Available" />
                            )}
                        </div>
                    )}

                </div>
            </div>
        </div>
    );
};

export default GS1Notes;
