import React, { useState, useEffect } from "react";
import { GetGSOverView } from "services/freeresources/freeresources";
import { errorResponse, failResponse } from "config/config";
import { FaArrowLeft, FaAngleLeft, FaCaretLeft, FaChevronLeft, FaLongArrowAltLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import '../Syllabus.scss'; // Import your custom CSS file

const GS1OverView = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Initial mobile check
    const [GS1OverView, setGS1OverView] = useState();
    const navigate = useNavigate(); // Initialize useNavigate

    useEffect(() => {
        // Function to fetch the GS1OverView
        const fetchUPSCGS1OverView = async () => {
          try {
              const res = await GetGSOverView({ sectionid: 1 });
              if (res.code === 200 && res.data.gsOverview.length > 0) {
                  setGS1OverView(res.data.gsOverview[0].content);
              } else {
                  failResponse(res);
              }
          } catch (err) {
              errorResponse(err);
          }
      };
      

        // Function to update isMobile state on window resize
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        fetchUPSCGS1OverView(); // Fetch GS1OverView on component mount

        window.addEventListener('resize', handleResize); // Listen for window resize
        return () => {
            window.removeEventListener('resize', handleResize); // Clean up listener on unmount
        };
        
    }, []); // Empty dependency array ensures this runs only once (on mount)

    // Handle back button functionality
    const handleBack = () => {
        navigate('/freeresources/FRAboutUPSC'); // Use navigate to go back to the specified route
    };

    return (
        <div className="syllabus-container">
            {isMobile && (
                <div className="mobile-back-arrow" onClick={handleBack}>
                    <FaChevronLeft style={{ color: '#181822', fontSize: '24px' }} /> {/* Font Awesome Arrow Icon */}
                </div>
            )}
            <div className="subsection-prelims-syllabus-container">
            <span onClick={() => navigate("/freeresources/FRMainsGS1Notes")} className="gsnotespan" style={{cursor:"pointer", color:"#0056b3", fontWeight:"600"}}>GS I Notes</span>
                <div dangerouslySetInnerHTML={{ __html: GS1OverView }} />
            </div>
        </div>
    );
};

export default GS1OverView;
