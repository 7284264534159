import OurLocation from "components/our-location/OurLocation";
import React, { useEffect, useRef, useState } from "react";
import "./courseenquiry.scss";
import { FaStar } from "react-icons/fa";
import { MdOutlineLanguage } from "react-icons/md";
import { ImCalendar } from "react-icons/im";
import { FaDownload } from "react-icons/fa";
import { MdOutlinePictureAsPdf } from "react-icons/md";
import { IoIosArrowDown } from "react-icons/io";
// import image1 from "./images/compare.png";
// import image2 from "./images/student-journey-v2.png";
// import image3 from "./images/location.png";
// import video from "./video/y2mate.com - Read More  Read Less Button with JavaScript  No Plugin HTML CSS JavaScript Tutorial_v720P.mp4";
// import video from "assets/videos/video.mp4";
// import video from "https://s3.ap-south-1.amazonaws.com/test.shubhraviraj.in/svr/1733139727489.mp4";
import CommonModal from "components/modal-box/CommonModal";
import { Link } from "react-router-dom";
import { Input } from "components/Form";
import { validateEmail } from "config/utils";
import useButtonLoader from "hooks/useButtonLoader";
import toast from "react-hot-toast";
import { errorResponse, failResponse } from "config/config";
import { BsArrowRight } from "react-icons/bs";
import CourseEnquiryRegisterModal from "./modal/CourseEnquiryRegisterModal";

export default function NewPage() {
  // const video = "https://s3.ap-south-1.amazonaws.com/test.shubhraviraj.in/svr/1733139727489.mp4";
  const [activeIndex, setActiveIndex] = useState(null); // Track the index of the active sectio
  const videoRef = useRef(null);
  const [flag, setFlag] = useState(false);
  const [submitButtonRef, setSubmitLoading] = useButtonLoader();



  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  }, []);

  
  return (
    <>
      <div className="content-page">
        <div className="content">
          <div className="content-left" onwheel="syncScroll(event, 'left')">
            <h3>
              If you are a serious UPSC aspirant and do not want to waste your
              attempt,
              <br />
              Join Us
            </h3>

            <hr />
            <div className="video-container">
              <video
                ref={videoRef}
                src={
                  "https://s3.ap-south-1.amazonaws.com/test.shubhraviraj.in/svr/1733139727489.mp4"
                }
                controls
                autoPlay
                muted
              ></video>
              <button
                onClick={() => {
                  setFlag(true);
                }}
              >
                Enquire Now
              </button>
            </div>
            <h3 className="head2">What we offer:</h3>
            <p className="para2"></p>
            <table style={{ color: "#1754d8" }}>
              <tr>
                {/* <th>Batch Deliverable</th> */}
                <td>1.</td>
                <td>
                  {" "}
                  Renowned and credible TEACHERS for General Studies and
                  Optional Subjects.
                </td>
              </tr>
              <tr>
                <td>2.</td>
                <td> Targeted ANSWER WRITING</td>
              </tr>
              <tr>
                <td>3.</td>
                <td> Comprehensive NOTES and study material</td>
              </tr>
              <tr>
                <td>4.</td>
                <td> WORKBOOKS based practice and revision</td>
              </tr>
              <tr>
                <td>5.</td>
                <td> HANDHOLDING till the Interview</td>
              </tr>
              <tr>
                <td>6.</td>
                <td>
                  {" "}
                  HOLISTIC and INTEGRATED preparation for Prelims and Mains
                </td>
              </tr>
              <tr>
                <td>7.</td>
                <td> Basic to advanced STRUCTURED course </td>
              </tr>
              <tr>
                <td>8.</td>
                <td>
                  {" "}
                  Quality ENRICHMENT Program before Prelims and Mains
                </td>{" "}
              </tr>
              <tr>
                <td>9.</td>
                <td>
                  {" "}
                  Regular TESTS to track, evaluate and improve students’
                  performance
                </td>
              </tr>
              <tr>
                <td>10.</td>
                <td>
                  {" "}
                  In-house LIBRARY to provide a conducive environment to study
                </td>
              </tr>
              <tr>
                <td>11.</td>
                <td>
                  {" "}
                  Prelims-specific The Hindu-based CURRENT AFFAIRS content
                </td>
              </tr>
              <tr>
                <td>12.</td>
                <td>
                  {" "}
                  MAINS-SPECIFIC annotated The Hindu-based Current Affairs
                </td>
              </tr>
              <tr>
                <td>13.</td>
                <td> WEEKLY Current Affairs Classes</td>
              </tr>
              <tr>
                <td>14.</td>
                <td> PYQ discussion for Prelims and Main</td>
              </tr>
              <tr>
                <td>15.</td>
                <td>
                  {" "}
                  Special SESSIONS by The Hindu Columnists/authors/ on topical
                  issues
                </td>
              </tr>
              <tr>
                <td>16.</td>
                <td>
                  {" "}
                  Special focus on high-scoring papers like ESSAYS AND ETHICS.
                </td>
              </tr>
            </table>

            <hr />
            <h3 className="head2">How we make you exam-ready:</h3>
            <table style={{ color: "#1754d8" }}>
              <thead>
                <tr>
                  <td colSpan={2}>Prelims : </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1.</td>
                  <td> NCERT Classes by GS faculties and renowned teachers</td>
                </tr>
                <tr>
                  <td>2.</td>
                  <td> NCERT-based objective tests</td>
                </tr>
                <tr>
                  <td>3.</td>
                  <td> GS and CSAT classes from basic to advanced</td>
                </tr>
                <tr>
                  <td>4.</td>
                  <td> Every week sectional tests for PRE</td>
                </tr>
                <tr>
                  <td>5.</td>
                  <td> Quality Enrichment Classes for Rapid revision</td>
                </tr>
                <tr>
                  <td>6.</td>
                  <td> WORKBOOKS for practice and revision</td>
                </tr>
                <tr>
                  <td>7.</td>
                  <td> PYQ discussion and Full-length Test Series</td>
                </tr>
                <tr>
                  <td>8.</td>
                  <td>
                    {" "}
                    PERSONAL MENTORSHIP and progress tracking for each student
                  </td>
                </tr>
                <tr>
                  <td>9.</td>
                  <td>
                    {" "}
                    Prelims-specific The Hindu-based CURRENT AFFAIRS content
                  </td>
                </tr>
              </tbody>
            </table>
            <hr />

            <table style={{ color: "#1754d8" }}>
              <thead>
                <tr>
                  <td colSpan={2}>Mains : </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1.</td>
                  <td> Weekly Answer Writing for Mains.</td>
                </tr>
                <tr>
                  <td>2.</td>
                  <td> GS classes by Renowned and credible TEACHERS</td>
                </tr>
                <tr>
                  <td>3.</td>
                  <td> Progress Tracking</td>
                </tr>
                <tr>
                  <td>4.</td>
                  <td>
                    {" "}
                    Mains Test series and Quality Enrichment Classes along with
                    Personalised Mentorship
                  </td>
                </tr>
                <tr>
                  <td>5.</td>
                  <td>
                    {" "}
                    Special focus on high-scoring papers like ESSAYS AND ETHICS
                  </td>
                </tr>
                <tr>
                  <td>6.</td>
                  <td> Comprehensive NOTES and study material</td>
                </tr>
                <tr>
                  <td>7.</td>
                  <td>
                    {" "}
                    MAINS-SPECIFIC annotated The Hindu-based Current Affairs
                  </td>
                </tr>
                <tr>
                  <td>8.</td>
                  <td> Weekly Current Affairs classes</td>
                </tr>
              </tbody>
            </table>

            <hr />
            <table style={{ color: "#1754d8" }}>
              <thead>
                <tr>
                  <td colSpan={2}>Interview : </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1.</td>
                  <td> DAF</td>
                </tr>
                <tr>
                  <td>2.</td>
                  <td>
                    {" "}
                    Focused interview strategy along with mock Interviews.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="content-right" onwheel="syncScroll(event, 'right')">
            <div className="video-container">
              <video
                ref={videoRef}
                src={
                  "https://s3.ap-south-1.amazonaws.com/test.shubhraviraj.in/svr/1733139727489.mp4"
                }
                controls
                autoPlay
                muted
              ></video>
              <button onClick={() => setFlag(true)}>Enquire Now</button>
            </div>
          </div>
        </div>
      </div>

      {/* ////////////////////// Number Data /////////////////// */}
      <div className="number_data spacing">
        <div className="container">
          <div className="number_data_content">
            <ul>
              <li>
                <h3>50,000 + </h3> <span>Students Tutored</span>
              </li>
              <li>
                <h3>60 + </h3> <span>Courses Offered</span>
              </li>
              <li>
                <h3>80 + </h3> <span>Experienced faculty</span>
              </li>
              <li>
                <h3>16 + </h3> <span>Centers across country</span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* ////////////////////// Our Location /////////////////// */}
      <OurLocation
        description={
          "Learn and train with top faculty from any one of our 17 locations across the country"
        }
      />
      {/* ************************************************** Register Modal / Course Enquiry Modal ************************************************** */}
      <CourseEnquiryRegisterModal flag={flag} setFlag={setFlag} />
    </>
  );
}
