import { decryptData, encryptData } from "config/config";
import { instance as axios } from "config/axiosInstance";
import { getBrowserName, isLoggedIn } from "config/utils";

export const getPaymentLinkUserDetails = async (data = {}) => {
  return decryptData(
    await axios.get(
      `/get-payment-link-user-details?reqData=${encryptData({
        ...data,
        platformType: getBrowserName(),
      })}`
    )
  );
};