import React, { useEffect, useState } from "react";
import "./Syllabus.scss";
import { useNavigate } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";
import { errorResponse, failResponse } from "config/config";

const PSIRPaper1 = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Initial mobile check
    const [content, setContent] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        setContent(`<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <style>
        .Eligibilityh3 {
            font-size: 18px;
            margin-bottom: 10px;
            text-align: center;
        }

        .Eligibilityp {
            margin-bottom: 8px;
            font-size: 15px;
            font-weight: 500;
            margin-top: 10px;
        }
        body{
        line-height:35px;
        }
        .title{
        color:#00239c;
        }
        .title1{
        color:#D2042D;
        }
        .pagetitle{
        color:#00239c;
        text-align: center;font-size: 26px;
        }
    </style>
</head>
<body>
    <h3 class="pagetitle">PSIR Syllabus Paper 1</h3>
    <strong class="title1">PAPER-I</strong>
    <div class="sub-section">
  <strong class="title">Paper 1 Section A - Political Theory and Indian Politics</strong>
  <ol>
    <li><strong>Political Theory: meaning and approaches.</strong>
      <p>To lay the foundational emphasis on the systemic study and philosophical analysis of political phenomenon. It encompasses traditional approaches (focusing on values and philosophy), modern approaches (empirical and scientific methods), and critical approaches (analyzing power structures). It combines both normative ("ought") and descriptive ("is") elements.</p>
    </li>
    
    <li><strong>Theories of state: Liberal, Neo-liberal, Marxist, Pluralist, Post-colonial, and Feminist.</strong>
      <p>Theories of state examine different perspectives on the state's nature, role, and organization. These include liberal (individual rights), neo-liberal (market freedom), Marxist (class oppression), pluralist (competing groups), post-colonial (colonial impact), and feminist (gender critique) views. Each theory offers distinct frameworks for understanding state power, legitimacy, and functions.</p>
    </li>
    
    <li><strong>Justice: Conceptions of justice with special reference to Rawl’s theory of justice and its communitarian critiques.</strong>
      <p>To examine the theories of fairness, rights, and distribution in society. Key aspects include procedural justice (fair processes), distributive justice (fair allocation of resources), social justice (equality and rights), and global justice. Major thinkers include Rawls (justice as fairness), Nozick (libertarian justice), and Sen (capability approach).</p>
    </li>
    
    <li><strong>Equality: Social, political, and economic; the relationship between equality and freedom; Affirmative action.</strong>
      <p>The fundamental political concept examining fair distribution of rights, opportunities, and resources. Key dimensions include formal equality (legal rights), substantive equality (actual outcomes), political equality (voting rights), social equality (status), and economic equality. Major debates center on equality versus liberty, affirmative action, and positive discrimination.</p>
    </li>
    
    <li><strong>Rights: Meaning and theories; different kinds of rights; Concept of Human Rights.</strong>
      <p>To understand justified claims or entitlements recognized by law and society. These include fundamental rights (life, liberty), socio-economic rights (education, work), and Human Rights (environmental, cultural). Key debates focus on universality versus cultural relativism, individual versus community rights, and state obligations.</p>
    </li>
    
    <li><strong>Democracy: Classical and contemporary theories; different models of democracy—representative, participatory, and deliberative.</strong>
      <p>Democracy studies forms of government based on popular sovereignty, representation, and participation. Key aspects include direct vs representative democracy, liberal democracy, participatory democracy, and deliberative democracy. Major themes cover electoral systems, democratic institutions, civic participation, majoritarianism vs pluralism, and challenges to democratic governance.</p>
    </li>
    
    <li><strong>Concept of power: hegemony, ideology, and legitimacy.</strong>
      <p>Power is fundamental to political science as it explains how states, institutions, and actors interact and influence each other. It's crucial for understanding international relations, domestic politics, policy-making, and global governance. The concept helps analyze everything from state behavior to social movements, making it essential for comprehending political phenomena.</p>
    </li>
    
    <li><strong>Political Ideologies: Liberalism, Socialism, Marxism, Fascism, Gandhism, and Feminism.</strong>
      <p>Political ideologies are systematic sets of ideas that shape political thinking and behavior. The main ones include liberalism (individual rights), socialism (equality), fascism (authoritarian nationalism), Gandhism (non-violence, truth, and self-rule), and feminism (women's rights). They are crucial for understanding political movements and governance.</p>
    </li>
    
    <li><strong>Indian Political Thought: Dharmashastra, Arthashastra, and Buddhist Traditions; Sir Syed Ahmed Khan, Sri Aurobindo, M. K. Gandhi, B. R. Ambedkar, M. N. Roy.</strong>
      <p>Covers ancient texts (Arthashastra, Dharmashastras), as well as modern thinkers. It emphasizes concepts like dharma, secularism, social justice, swaraj, and nationalism. Focus is on both traditional philosophy and colonial/post-colonial perspectives.</p>
    </li>
    
    <li><strong>Western Political Thought: Plato, Aristotle, Machiavelli, Hobbes, Locke, John S. Mill, Marx, Gramsci, Hannah Arendt.</strong>
      <p>Includes key philosophers from ancient Greece (Plato, Aristotle) to modern times (Mill, Arendt). It examines evolving concepts of state, liberty, justice, democracy, and rights. Major themes include social contract theories (Hobbes, Locke, Rousseau), power relationships, and critiques of modern political systems.</p>
    </li>
  </ol>

  <strong class="title">Paper 1 Section B - Indian Government and Politics</strong>
    <ol>
    <li><strong>Indian Nationalism:</strong>
      <ul>
        <li><strong>Political Strategies of India’s Freedom Struggle:</strong> Constitutionalism to mass Satyagraha, Noncooperation, Civil Disobedience; Militant and Revolutionary Movements, Peasant and Workers Movements. The struggle evolved from constitutional methods to mass mobilization strategies, including socio-economic struggles of peasants and workers, collectively challenging British colonial rule.</li>
        <li><strong>Perspectives on Indian National Movement:</strong> Liberal, Socialist, Marxist, Radical Humanist, and Dalit. India's national movement had multiple ideological perspectives, each offering unique interpretations of colonial resistance and national liberation.</li>
      </ul>
    </li>
    
    <li><strong>Making of the Indian Constitution:</strong> Legacies of British rule; different social and political perspectives. The Indian Constitution emerged from British administrative legacies while integrating diverse social and political viewpoints.</li>

    <li><strong>Salient Features of the Indian Constitution:</strong>
      <ul>
        <li><strong>The Preamble, Fundamental Rights and Duties, Directive Principles:</strong> Covers constitutional foundations through sovereignty, socialism, secularism, democracy, justice, individual liberties, and welfare state goals.</li>
        <li><strong>Parliamentary System:</strong> Structure, cabinet system, Prime Minister’s role, legislative-executive fusion, bicameralism, and federal features.</li>
        <li><strong>Amendment Procedures; Judicial Review and Basic Structure Doctrine:</strong> Examines Article 368, Parliament’s amending powers, evolution of Judicial Review, and Kesavananda Bharati case.</li>
      </ul>
    </li>

    <li><strong>Principal Organs of Government:</strong>
      <ul>
        <li><strong>Union Government:</strong> Executive, Legislature, and Supreme Court. Analysis of constitutional design versus actual functioning.</li>
        <li><strong>State Government:</strong> Executive, Legislature, and High Courts. Focuses on governance at the state level and center-state relations.</li>
      </ul>
    </li>

    <li><strong>Grassroots Democracy:</strong> Panchayati Raj and Municipal Government; Significance of 73rd and 74th Amendments; Grassroots movements. Decentralization, urban and rural governance, and participatory democracy.</li>

    <li><strong>Statutory Institutions/Commissions:</strong> Election Commission, Comptroller and Auditor General, Finance Commission, UPSC, NCSC, NCST, NCW, NHRC, NCM, NBC. Their roles, powers, and impact on governance.</li>

    <li><strong>Federalism:</strong> Constitutional provisions; changing center-state relations; regional aspirations; inter-state disputes.</li>

    <li><strong>Planning and Economic Development:</strong> Nehruvian and Gandhian perspectives, Green Revolution, land reforms, liberalization, and economic reforms.</li>

    <li><strong>Caste, Religion, and Ethnicity in Indian Politics:</strong> Identity politics, caste mobilization, religious pluralism, communalism, and reservation politics.</li>

    <li><strong>Party System:</strong> National and regional parties, ideological bases, coalition politics, pressure groups, electoral behavior trends, and socio-economic profile of legislators.</li>

    <li><strong>Social Movements:</strong> Civil liberties, human rights, women’s movements, environmental activism (Chipko, Narmada), policy impacts.</li>
  </ol>
</div>


      
    
    </body>
</html>
`);
    });

    useEffect(() => {
        // Function to fetch the content
        const fetchUPSCcontent = async () => {
            try {
                // const res = await GetUPSCcontent();
                // if (res.code === 200) {
                //   const { htmlcontent } = res.data.content[3];
                //   setContent(htmlcontent);
                // } else {
                //   failResponse(res);
                // }
            } catch (err) {
                errorResponse(err);
            }
        };

        // Function to update isMobile state on window resize
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        fetchUPSCcontent(); // Fetch content on component mount

        window.addEventListener("resize", handleResize); // Listen for window resize
        return () => {
            window.removeEventListener("resize", handleResize); // Clean up listener on unmount
        };
    }, []); // Empty dependency array ensures this runs only once (on mount)

    // Handle back button functionality
    const handleBack = () => {
        navigate("/freeresources/FRMains"); // Use navigate to go back to the specified route
    };

    return (
        <div className="syllabus-container">
            {isMobile && (
                <div className="mobile-back-arrow" onClick={handleBack}>
                    <FaChevronLeft style={{ color: "#181822", fontSize: "24px" }} />{" "}
                    {/* Font Awesome Arrow Icon */}
                </div>
            )}
            <div className="subsection-prelims-syllabus-container">
                <div dangerouslySetInnerHTML={{ __html: content }} />
            </div>
        </div>
    );
};

export default PSIRPaper1;
