import { decryptData, encryptData } from "config/config";
import { instance as axios } from "config/axiosInstance";
import { getBrowserName } from "config/utils";
import { isLoggedIn } from "config/utils";

export const SubmitCourseEnquiry = async (data) => {
    return decryptData(
      await axios.post(`/submit-course-inquiry`, {
        reqData: encryptData({
          ...data,
          access_token: isLoggedIn(),
        }),
      })
    );
  };
