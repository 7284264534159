import React, { useEffect, useState } from "react";
import "../Syllabus.scss";
import { useNavigate } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";
import { errorResponse, failResponse } from "config/config";

const GS4 = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Initial mobile check
  const [content, setContent] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    setContent(`<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <style>
        .Eligibilityh3 {
            font-size: 18px;
            margin-bottom: 10px;
            text-align: center;
        }

        .Eligibilityp {
            margin-bottom: 8px;
            font-size: 17px;
            font-weight: 500;
            margin-top: 10px;
        }
		body{
		line-height:35px;
		}
		.title{
		color:#00239c;
    font-size: 17px;
		}
		.title1{
		color:#D2042D;
    font-size: 17px;
		}
		.pagetitle{
		color:#00239c;
		text-align: center;font-size: 26px;
		}
    .ulgs{
    font-size: 17px;
    }
    .ligs{
        font-size: 17px;
    }
    </style>
</head>
<body>
    <h3 class="pagetitle">UPSC Mains General Studies Paper 4 Overview</h3><br/>
    <p class="Eligibilityp">General Studies Paper 4, also known as <strong><em><u>Ethics, Integrity, and Aptitude,</u></em></strong> evaluates a candidate’s ethical values, integrity, and aptitude for civil services. Unlike other papers, GS Paper 4 requires both theoretical understanding and the ability to apply ethical principles to practical situations. This paper focuses on the candidate’s character, moral reasoning, and decision-making abilities, which are crucial for responsible governance and public administration.</p>
    
    <hr/>

    <strong class="title1"><u>Question Format</u></strong>
    <ul class="ulgs" style="list-style-type: disc; margin-left: 20px;padding-left:20px;">
        <li class="ligs"><strong>Number of Questions:</strong> 13 theory questions (10 marks each) and 6 case studies (20 marks each).</li>
        <li class="ligs"><strong>Languages:</strong> Questions are provided in both Hindi and English.</li>
        <li class="ligs"><strong>Total Marks:</strong> 250 marks</li>
    </ul>
    <strong class="title1"><u>Word Limits</u></strong>
    <ul class="ulgs" style="list-style-type: disc; margin-left: 20px;padding-left:20px;">
    <li class="ligs"><strong>Theory Questions (10-mark): </strong> Maximum of 150 words</li>
    <li class="ligs"><strong>Case Studies (20-mark): </strong> Requires comprehensive yet concise responses within the specified word limit of 250 words.</li>
    </ul>

    <hr/>
    <strong class="title1"><u>Key Subject Areas</u></strong>
        <br/>
          <strong class="title" style="padding-left:20px">1.	Ethics and Human Interface:</strong><br/>
          <ul class="ulgs" style="list-style-type: circle; margin-left: 20px;padding-left:40px;">
                <li class="ligs"><strong>Major Topics: </strong> Ethical foundations, values, and human behavior. This section explores the definitions and dimensions of ethics and morality, emphasizing the role of values in shaping actions.</li>
                <li class="ligs"><strong>Sources and Approach: </strong> <em>2<sup>nd</sup> ARC Report</em> on <strong><u>“Ethics in Governance”</u></strong> provides a foundational understanding of ethics. Additional readings in psychology can aid in exploring the interface between ethics and human behavior.</li>
           </ul><br/>
          
          <strong class="title" style="padding-left:20px">2.	Attitude:</strong><br/>
          <ul class="ulgs" style="list-style-type: circle; margin-left: 20px;padding-left:40px;">
                <li class="ligs"><strong>Major Topics: </strong> Types, formation, and influence of attitudes on behavior, with a focus on public administration.</li>
                <li class="ligs"><strong>Sources and Approach: </strong> <em>2<sup>nd</sup> ARC Report</em> insights on public service values and behavioral standards. Supplementary readings on psychology are useful for understanding the impact of attitudes on conduct in public service.</li>
           </ul><br/>
           
           <strong class="title" style="padding-left:20px">3.	Aptitude and Foundational Values for Civil Services:</strong><br/>
           <ul class="ulgs" style="list-style-type: circle; margin-left: 20px;padding-left:40px;">
                <li class="ligs"><strong>Major Topics: </strong> Core values essential for public administration, such as integrity, impartiality, empathy, and dedication to public service.</li>
                <li class="ligs"><strong>Sources and Approach: </strong> The <em>2<sup>nd</sup> ARC Report</em> emphasizes values necessary for civil services, highlighting examples of model public servants who embody these values. Study cases on administrative reforms for practical insights.</li>
           </ul><br/>
          
          <strong class="title" style="padding-left:20px">4.	Emotional Intelligence (EI):</strong><br/>
          <ul class="ulgs" style="list-style-type: circle; margin-left: 20px;padding-left:40px;">
                <li class="ligs"><strong>Major Topics: </strong> Significance of EI in handling interpersonal relationships and decision-making in administration.</li>
                <li class="ligs"><strong>Sources and Approach: </strong> <em>2<sup>nd</sup> ARC Report</em> on emotional intelligence in civil service training, with additional readings on EI from psychology sources. Case studies where EI plays a role can illustrate its importance in effective public administration.</li>
           </ul><br/>
           
           <strong class="title" style="padding-left:20px">5.	Contributions of Thinkers and Philosophers:</strong><br/>
           <ul class="ulgs" style="list-style-type: circle; margin-left: 20px;padding-left:40px;">
          <li class="ligs"><strong>Major Topics: </strong> Ethical philosophies and contributions from Indian and Western thinkers, providing frameworks for public administration.</li>
          <li class="ligs"><strong>Sources and Approach: </strong> <em>2<sup>nd</sup> ARC Report</em> references ethical frameworks relevant to governance, with further readings on thinkers like Gandhi, Plato, and Kant to understand the moral perspectives shaping public service.</li>
                <div style="border:1px solid #000;padding:0 10px;border-radius:5px;">
                  <strong class="title"><u>Word Limits</u></strong>
                  <p>Quotation-based questions in the UPSC Mains Exam are designed to assess a candidate’s interpretive skills, analytical thinking, and ability to connect abstract ideas with real-life examples, especially in General Studies Paper 4. These questions typically present a quote related to ethics, governance, or philosophy and ask candidates to explain or critically analyze its relevance. Here’s a step-by-step approach to tackle these questions effectively:</p>
                  
                  <hr/>
                  
                  <strong class="title">Steps to Solve Quotation-Based Questions</strong><br/>
                  <strong class="title" style="padding-left:20px">1.	Understand the Quote:</strong><br/>
                  <ul class="ulgs" style="list-style-type: circle; margin-left: 20px;padding-left:40px;">
                    <li class="ligs">Begin by carefully reading and interpreting the quotation. Identify the main theme or idea. Ask yourself: What is the central message? Does it pertain to ethics, governance, human behavior, or values?</li>
                    <li class="ligs">Break down complex phrases, and try to understand the context in which the quote might be applicable.</li>
                  </ul><br/>
                  
                  <strong class="title" style="padding-left:20px">2.	Explain the Core Message:</strong><br/>
                  <ul class="ulgs" style="list-style-type: circle; margin-left: 20px;padding-left:40px;">
                    <li class="ligs">Start your answer by paraphrasing the quotation in simpler terms. Explain what the quote implies in your own words without deviating from its original meaning.</li>
                    <li class="ligs">Use clear language to make the interpretation easy to understand. For instance, if the quote talks about integrity, explain what integrity means in a general sense and why it is important.</li>
                    </ul><br/>

                  <strong class="title" style="padding-left:20px">3.	Link to Core Ethical Concepts:</strong><br/>
                  <ul class="ulgs" style="list-style-type: circle; margin-left: 20px;padding-left:40px;">
                    <li class="ligs">Relate the quotation to core ethical principles or values such as integrity, empathy, honesty, or accountability. This is where theoretical knowledge from sources like the 2nd ARC Report and ethical principles covered in ethics syllabus becomes useful.</li>
                    <li class="ligs">Mention relevant thinkers or ethical frameworks, if applicable. For instance, if the quote is about justice, relate it to ideas by philosophers like Plato or John Rawls on fairness and justice.</li>
                  </ul><br/>

                  <strong class="title" style="padding-left:20px">4.	Provide Real-Life Examples:</strong><br/>
                  <ul class="ulgs" style="list-style-type: circle; margin-left: 20px;padding-left:40px;">
                    <li class="ligs">Illustrate the relevance of the quotation with real-life examples, especially from public administration, governance, or historical events.</li>
                    <li class="ligs">Examples can include actions of well-known public figures, government policies, or case studies where the principle illustrated in the quote was applied or challenged. For instance, if the quote pertains to resilience, discuss how certain leaders or policies demonstrated resilience in times of crisis.</li>
                  </ul><br/>

                  <strong class="title" style="padding-left:20px">5.	Connect to Civil Services or Governance:</strong><br/>
                  <ul class="ulgs" style="list-style-type: circle; margin-left: 20px;padding-left:40px;">
                    <li class="ligs">Discuss the relevance of the quote in the context of civil services or governance. Explain how the idea is essential for a civil servant and why it matters in effective administration.</li>
                    <li class="ligs">Link the quote to civil service values such as integrity, dedication, or neutrality, illustrating how these principles ensure ethical and transparent governance.</li>
                  </ul><br/>

                  <strong class="title" style="padding-left:20px">6.	Conclude with a Reflective Statement:</strong><br/>
                  <ul class="ulgs" style="list-style-type: circle; margin-left: 20px;padding-left:40px;">
                    <li class="ligs">Summarize the essence of the quotation and reaffirm its significance in fostering ethical conduct in society or governance.</li>
                    <li class="ligs">If possible, relate the conclusion back to the duties of a civil servant, reinforcing the message that values like honesty, empathy, or accountability are the backbone of public service.</li>
                    </ul>
                  
                  <hr/>

                  <strong class="title">Example of Approach for a Quotation-Based Question</strong>
                  <p><strong>Question:</strong> <em>"The true measure of a man is how he treats someone who can do him absolutely no good."</em> — Samuel Johnson. Discuss its relevance to public administration.</p>     
                  <strong class="title">Answer Structure:</strong><br/>
                  <ul class="ulgs" style="list-style-type: disc; margin-left: 20px;padding-left:20px;">
                  <li class="ligs"><strong>Introduction/Paraphrasing:</strong> Begin by explaining that the quote emphasizes kindness, empathy, and selflessness, suggesting that a person’s character is best revealed in their behavior toward those who offer no material benefit.</li>
                  <li class="ligs"><strong>Core Ethical Concepts:</strong>Relate this to ethical principles like compassion, integrity, and impartiality, which are essential for a public servant. It underscores the importance of treating all individuals with respect, irrespective of their social or economic status.</li>
                  <li class="ligs"><strong>Example: </strong> Illustrate with examples from public administration, such as how a civil servant helps vulnerable sections of society — the poor, marginalized, or minorities — without any expectation of reward or recognition.</li>
                    <li class="ligs"><strong>Connection to Civil Services: </strong>Emphasize that public administration requires treating everyone with fairness and respect, reflecting the commitment to serve all citizens impartially, especially those who lack influence or power.</li>
                    <li class="ligs"><strong>Conclusion: </strong>Conclude by stating that a true public servant embodies these values, ensuring that the principles of empathy, justice, and integrity are upheld in public life, thereby strengthening trust in governance.</li>
                  </ul>
                  
                  <hr/>
                  
                  <strong class="title">Tips for Answering Quotation-Based Questions</strong><br> 
                  <ul class="ulgs" style="list-style-type: disc; margin-left: 20px;padding-left:20px;">
                  <li class="ligs"><strong>Keep Interpretation Simple: </strong> Avoid overcomplicating the quote’s meaning. Aim for clarity.</li>
                  <li class="ligs"><strong>Stay Relevant: </strong> Ensure that all examples and explanations directly relate to the core theme of the quotation.</li>
                  <li class="ligs"><strong>Use Real-World Applications: </strong> Where possible, use current events or well-known examples to enhance relevance and relatability.</li>
                  <li class="ligs"><strong>Practice Precision: </strong> Be concise, especially within word limits. Answering precisely is key to staying within the stipulated limit without losing depth.</li>
                  </ul>
                  <p>By following this structured approach, candidates can effectively tackle quotation-based questions, showcasing their ability to interpret complex ideas, relate them to ethics and governance, and demonstrate their practical application in public administration.</p>

                </div>
           
          </ul><br/>
          
          <strong class="title" style="padding-left:20px">6.	Public/Civil Service Values and Ethics in Public Administration:</strong><br/>
          <ul class="ulgs" style="list-style-type: circle; margin-left: 20px;padding-left:40px;">
            <li class="ligs"><strong>Major Topics: </strong> Standards of conduct, accountability, neutrality, and dedication essential in public administration.</li>
            <li class="ligs"><strong>Sources and Approach: </strong> <em> 2<sup>nd</sup> ARC Report</em> on ethics and accountability in public service provides comprehensive insights into ethical governance standards and practices.</li>
          </ul><br/>
          
          <strong class="title" style="padding-left:20px">7.	Probity in Governance:</strong><br/>
          <ul class="ulgs" style="list-style-type: circle; margin-left: 20px;padding-left:40px;">
            <li class="ligs"><strong>Major Topics: </strong> Importance of integrity, codes of conduct, anti-corruption measures, and transparency mechanisms in governance.</li>
            <li class="ligs"><strong>Sources and Approach: </strong> <em> 2<sup>nd</sup> ARC Report</em> sections on probity and transparency serve as primary sources, with case laws and government reports offering additional insights into frameworks ensuring ethical governance.</li>
          </ul><br/>
          
          <strong class="title" style="padding-left:20px">8.	Case Studies on the Above Issues:</strong><br/>
           <ul class="ulgs" style="list-style-type: circle; margin-left: 20px;padding-left:40px;">
          <li class="ligs"><strong>Major Topics: </strong> Practical application of ethical principles to real-life dilemmas faced by civil servants, including conflict resolution, resource allocation, and public vs. personal interest.</li>
          <li class="ligs"><strong>Sources and Approach: </strong> <em>2<sup>nd</sup> ARC Report</em> case studies provide examples of ethical dilemmas in governance. Practicing case studies from previous exams develops problem-solving skills and the ability to balance stakeholder interests.</li>
                <div style="border:1px solid #000;padding:0 10px;border-radius:5px;">
                  <strong class="title">Approach to Solving Case Studies in UPSC Mains</strong>
                  <p>Case studies in the UPSC Mains, particularly in General Studies Paper 4, are designed to test a candidate’s ethical decision-making skills, problem-solving abilities, and application of core values in real-life situations. Each case study presents a scenario with ethical dilemmas or governance issues, and candidates are expected to respond with integrity, empathy, and a practical mindset. Here’s a structured approach to tackle these case studies effectively:</p>
                  
                  <hr/>
                  
                  <strong class="title">Step-by-Step Approach to Solving Case Studies</strong><br/>
                  <strong class="title" style="padding-left:20px">1.	Carefully Read and Understand the Case:</strong><br/>
                  <ul class="ulgs" style="list-style-type: circle; margin-left: 20px;padding-left:40px;">
                    <li class="ligs">Read the case study thoroughly to understand the context, key issues, and ethical dilemmas presented. Identify the stakeholders involved and their interests or concerns.</li>
                    <li class="ligs">Highlight the core values, principles, and issues that the case addresses, such as integrity, transparency, empathy, accountability, or public welfare.</li>
                  </ul><br/>
                  
                  <strong class="title" style="padding-left:20px">2.	Identify the Ethical Dilemmas and Core Issues:</strong><br/>
                  <ul class="ulgs" style="list-style-type: circle; margin-left: 20px;padding-left:40px;">
                    <li class="ligs">Clearly define the ethical conflicts or challenges present in the situation. For instance, there may be a conflict between personal interests and public duty, or between adhering to rules and showing compassion.</li>
                    <li class="ligs">List the key issues in a bullet format if it helps organize thoughts. For instance, if a case involves environmental compliance vs. industrial growth, highlight this conflict explicitly.</li>
                    </ul><br/>

                  <strong class="title" style="padding-left:20px">3.	Outline Possible Courses of Action:</strong><br/>
                  <ul class="ulgs" style="list-style-type: circle; margin-left: 20px;padding-left:40px;">
                    <li class="ligs">Present multiple options for addressing the issue, considering both practical and ethical dimensions. Each option should be a plausible response to the dilemma.</li>
                    <li class="ligs">Describe the pros and cons of each option, focusing on its ethical soundness, feasibility, and potential impact on stakeholders. Discuss implications like public trust, long-term benefits, and rule adherence.</li>
                  </ul><br/>

                  <strong class="title" style="padding-left:20px">4.	Evaluate Each Option (Justify Your Choice):</strong><br/>
                  <ul class="ulgs" style="list-style-type: circle; margin-left: 20px;padding-left:40px;">
                    <li class="ligs">After listing options, evaluate each based on ethical principles like fairness, transparency, legality, and compassion. Highlight which course of action aligns best with these principles.</li>
                    <li class="ligs">Justify why you chose a specific course of action over others, showing that it balances ethical considerations with practical effectiveness.</li>
                  </ul><br/>

                  <strong class="title" style="padding-left:20px">5.	Detail the Chosen Solution:</strong><br/>
                  <ul class="ulgs" style="list-style-type: circle; margin-left: 20px;padding-left:40px;">
                    <li class="ligs">Explain the solution in clear terms, outlining specific steps or measures to implement it. For instance, if resolving a conflict with local communities, describe steps like stakeholder consultation or transparency measures.</li>
                    <li class="ligs">Ensure the solution demonstrates accountability, empathy, and respect for laws and ethical norms. Show how the solution benefits the larger public interest or resolves the issue fairly.</li>
                  </ul><br/>

                  <strong class="title" style="padding-left:20px">6.	Mention Long-Term Measures (if applicable):</strong><br/>
                  <ul class="ulgs" style="list-style-type: circle; margin-left: 20px;padding-left:40px;">
                    <li class="ligs">Suggest preventive or remedial actions to avoid similar ethical issues in the future. This could include policy recommendations, training programs for ethical conduct, or institutional reforms.</li>
                    <li class="ligs">Long-term solutions often add depth to your answer, showing foresight and a proactive approach to governance.</li>
                    </ul><br/>
                  
                  
                  <strong class="title" style="padding-left:20px">7.	Conclude with an Ethical Reflection:</strong><br/>
                  <ul class="ulgs" style="list-style-type: circle; margin-left: 20px;padding-left:40px;">
                    <li class="ligs">Conclude by summarizing the ethical values emphasized in your solution. Reinforce how the approach demonstrates integrity, transparency, and service orientation.</li>
                    <li class="ligs">Reflect briefly on the importance of ethical governance and decision-making in public service.</li>
                    </ul><br/>
                  
                  
                </div>
           
          </ul><br/>
          
          <hr/>

          <strong class="title">Exam Analysis:</strong>
          <p class="Eligibilityp">GS Paper 4 combines theory with case-based questions, focusing on current ethical challenges in governance and public administration. The 2nd ARC Report is a critical source for both theoretical and practical knowledge, offering a balanced approach to understanding ethics in real-world governance scenarios. Case studies demand the application of these principles to recent examples, testing candidates’ ability to resolve ethical dilemmas.</p>
           <br/>      
    </body>
</html>
`);
  });

  useEffect(() => {
    // Function to fetch the content
    const fetchUPSCcontent = async () => {
      try {
        // const res = await GetUPSCcontent();
        // if (res.code === 200) {
        //   const { htmlcontent } = res.data.content[3];
        //   setContent(htmlcontent);
        // } else {
        //   failResponse(res);
        // }
      } catch (err) {
        errorResponse(err);
      }
    };

    // Function to update isMobile state on window resize
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    fetchUPSCcontent(); // Fetch content on component mount

    window.addEventListener("resize", handleResize); // Listen for window resize
    return () => {
      window.removeEventListener("resize", handleResize); // Clean up listener on unmount
    };
  }, []); // Empty dependency array ensures this runs only once (on mount)

  // Handle back button functionality
  const handleBack = () => {
    navigate("/freeresources/FRMains"); // Use navigate to go back to the specified route
  };

  return (
    <div className="syllabus-container">
      {isMobile && (
        <div className="mobile-back-arrow" onClick={handleBack}>
          <FaChevronLeft style={{ color: "#181822", fontSize: "24px" }} />{" "}
          {/* Font Awesome Arrow Icon */}
        </div>
      )}
      <div className="subsection-prelims-syllabus-container">
      <span onClick={() => navigate("/freeresources/FRMainsGS4Notes")} className="gsnotespan" style={{cursor:"pointer", color:"#0056b3", fontWeight:"600"}}>GS IV Notes</span>

        <div dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    </div>
  );
};

export default GS4;
