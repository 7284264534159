

// export default TopBar;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getSiteSetting } from "services/TopBar/Topbar";
import { faMobileAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { clear } from "@testing-library/user-event/dist/clear";



const TopBar = () => {
  
const [siteSettings, setSiteSettings] = useState([]);

useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await getSiteSetting();
      setSiteSettings(response.data.config);
    } catch (error) {
      console.error("Error fetching site settings:", error);
    }
  };

  fetchData();
}, []);

const renderLinks = () => {
  let links = [];

  siteSettings.forEach((setting) => {
    
    if (setting.type === 3 && setting.status) {
      links.push(
        <p key={setting.id} className="mx-2 px-1">
          Register{" "}
          <Link to={setting.value}>
            here
          </Link>{" "}
          for all India Mock test.
        </p>
      );
    }
     else if (setting.type === 4 && setting.status) {
      links.push(
        <p key={setting.id} className="mx-2 px-1">
          Register{" "}
          <Link to={setting.value}>
            here
          </Link>{" "}
          for upcoming Workshop.
        </p>
      );
    }
  });

  // Add the separator between links
  links = links.reduce((acc, link, index) => [
    ...acc,
    link,
    index < links.length - 1 ? <span key={`separator-${index}`} className="separator">||</span> : null,
  ], []);

  return links;
};


const mobilerenderLinks = () => {
  let links = [];

  siteSettings.forEach((setting) => {
    
    if (setting.type === 3 && setting.status) {
       links.push(
        <p key={setting.id} className="rotatedText" style={{paddingBottom:"3px", fontSize: "13px", fontWeight: "600", textAlign: "center"}}>
          👉 Register{" "}
          <Link to={setting.value}>
            here
          </Link>{" "}
          for all India Mock test.
        </p>
      );
    }
     else if (setting.type === 4 && setting.status) {
     links.push(
        <p key={setting.id} className="rotatedText" style={{paddingBottom:"3px", fontSize: "13px", fontWeight: "600", textAlign: "center"}}>
          👉 Register{" "}
          <Link to={setting.value}>
            here
          </Link>{" "}
          for upcoming Workshop.
        </p>
      );
    }
  });

  
  return links;
};


return (
  <>
    {renderLinks().length > 0 && (
      <div className="subHeader">
        <span className="subHeader1 hide-on-mobile" style={{ float: "left", marginLeft: "130px", color: "rgb(24 67 162)" }}>
          <FontAwesomeIcon icon={faMobileAlt} style={{ marginRight: "5px" }} />
          9810212719
        </span>
        <div className="d-flex justify-content-center">
          <p className="handSymbol">👉</p>
          {renderLinks()}
          <p className="handSymbol left-fingure">👉</p>
        </div>
      </div>
    )}
  {/* <hr style={{margin:"0.2rem 0", color:"#edf5ff"}}/> */}
    {/* New mobile-only header */}
    <div className="mobileHeader">
      {
        mobilerenderLinks()
      }
     <p className="rotatedText" style={{ fontSize: "13px", paddingBottom:"3px", fontWeight: "600", textAlign: "center", color: "rgb(24 67 162)" }}> 
    <a style={{ color: "#151414cf" }}>👉 For contact support &nbsp;</a>
    <FontAwesomeIcon icon={faMobileAlt} style={{ marginRight: "5px" }} />
    9810212719
  </p>
</div>

  </>
);

};

export default TopBar;


// import React from "react";
// import { Link } from "react-router-dom";

// const TopBar = () => {
//   return (
//     <>
//       <div className="subHeader">
//         <div className="d-flex justify-content-center">
//           <p className="handSymbol">👉</p>
//           <p className="mx-2 px-1">
//             {/* Scholarship Exams are open now. Register <Link to="#">here</Link>{" "}
//             and get your course scholarship */}
//             Register <Link to="https://mocktest.shubhraviraj.in/">here</Link> for all India Mock test. || Register <Link to="https://events.shubhraviraj.in/welcome.html">here</Link> for upcoming Workshop
//           </p>
//           <p className="handSymbol left-fingure">👉</p>
//         </div>
//       </div>
//     </>
//   );
// };

