import React from "react";
import { courseAccess, momentDateFormat } from "config/utils";
import { Pageimg, TimeMachineimg } from "assets/icons";
import { Link } from "react-router-dom";
import { CourseNote } from "assets/images";
import moment from "moment";

const CourseCard = ({ course, isOnDemand }) => {
  const { CourseID, image, DisplayName, Platform, StartDate } = course;
  return (
    <>
      <div className="listing_card" key={CourseID}>
        <Link
          // to={`/course-detail/${DisplayName}/${CourseID}`}
          to={
            CourseID === '7f77e12b-c186-4934-9bfc-abf3f3ff4a28'
              ? `/courses/modular-courses/NCERT-foundation-course`
              : CourseID === 'f64976d1-9cda-45a2-aa84-13fc66534315'
                ? `/courses/psir-courses/psir-optional-classroom-course`
                : CourseID === 'ee52f2c9-6e82-4c89-b6ca-01f13b6335e9'
                  ? `/courses/psir-courses/psir-answer-writing-module`
                  : CourseID === '2e87fe0b-1f18-4aad-aac8-ad3fb389c0e2'
                    ? `/courses/psir-courses/psir-optional-online-course`
                    : CourseID === '5457a2b8-6314-4b32-8c61-0d750382104e'
                      ? `/courses/psir-courses/psir-on-demand-tablet-course`
                      : CourseID === '280e4431-6460-463c-aace-081bc8c4cce7'
                        ? `/courses/psir-courses/psir-postal-correspondence-course`
                        : CourseID === '22f0178d-e5be-4d1d-a524-ff247f882c47'
                          ? `/history-optional-classroom-course`
                          : CourseID === 'd22dea14-d7ce-4e06-a972-f9c3ef6518a3'
                            ? `/history-optional-online-course`
                            : CourseID === '56801f1b-ff6d-407e-b4a6-3a53d1f3a7b0'
                              ? `/courses/sociology-courses/sociology-optional-classroom-course`
                              : CourseID === '7e316a86-a888-4794-aef5-b4a69766e825'
                                ? `/courses/sociology-courses/sociology-optional-online-course`
                                : CourseID === '31547019-950b-41a1-9d38-3b9a455b0904'
                                  ? `/courses/sociology-courses/sociology-on-demand-tablet-course`
                                  : CourseID === '9be8b42a-ea81-4f5b-8cb5-18b0e57b1c3e'
                                    ? `/courses/sociology-courses/sociology-test-series`
                                    : CourseID === '0d04b1e8-4b67-44aa-8a54-afdafae1d7cf'
                                      ? `/courses/gs-courses/gs-foundation-classroom-course`
                                      : CourseID === '2269626d-c63f-4cdf-b270-1285c4c3f11e'
                                        ? `/courses/gs-courses/gs-foundation-online-course`
                                        : CourseID === 'a5db0ae6-f2b0-4de6-a4ac-356262aea68d'
                                          ? `/courses/gs-courses/qep-gs-crash-course-classroom`
                                          : CourseID === 'b0f96a3d-250a-4769-bdff-3e223df65efd'
                                            ? `/courses/gs-courses/qep-gs-crash-course-online`
                                            : CourseID === '06f634ce-d49a-4327-bd9e-fc412d3f0e48'
                                              ? `/courses/gs-courses/Integrated-GS-and-PSIR-Optional-Classroom-Course`
                                              : CourseID === '66a37d9f-abd6-4822-b56d-559db8a9cc63'
                                                ? `/courses/gs-courses/Integrated-GS-and-Sociology-Optional-Classroom-Course`
                                                : CourseID === '69204890-d049-4521-9f0d-594b1d624d4c'
                                                  ? `/Integrated-GS-and-History-Optional-Classroom-Course`
                                                  : CourseID === 'dbb6e35d-f703-4778-8157-6b3c89ecb343'
                                                    ? `/courses/gs-courses/Integrated-GS-and-PSIR-Optional-Online-Course`
                                                    : CourseID === 'df9db5b3-e8f1-428e-885c-68c91747e9c0'
                                                      ? `/courses/gs-courses/Integrated-GS-and-Sociology-Optional-Online-Course`
                                                      : CourseID === '84f8cf5d-482e-42ec-a49a-b00f434ecf57'
                                                        ? `/Integrated-GS-and-History-Optional-Online-Course`
                                                        : CourseID === 'bd04e35a-8620-465e-bc4a-b2b516cdbfb4'
                                                          ? `/courses/modular-courses/CSAT-foundation-course`
                                                          : CourseID === '53a90166-3367-45b1-b950-70bc3f41187b'
                                                            ? `/courses/modular-courses/essay-program`
                                                            : CourseID === '6b3ff0a9-49c7-42ce-a703-6274bc2d3dae'
                                                              ? `/gs-mentorship-program`
                                                              : CourseID === '5cf9d9f8-a8c9-4c5a-b403-1ed1cdcd4c2b'
                                                                ? `/gs-mentorship-online-program`
                                                                : CourseID === '28e68130-8e23-4893-97e1-54880dd2e031'
                                                                  ? `/psir-mentorship-program`
                                                                  : CourseID === '03d608ab-5ae5-4839-b736-591c0ac0bcea'
                                                                    ? `/psir-optional-video-batch`
                                                                    : CourseID === '1c5e7800-9a77-4e74-9ba9-b177377db789'
                                                                      ? `/polity-pyq-course`
                                                                      : CourseID === '6211b29a-1754-4116-a255-6a02e196cdbc'
                                                                        ? `/psir-mentorship-online-program`
                                                                        : CourseID === '77181a27-eaeb-44aa-8164-e8163ef71633'
                                                                          ? `/political-science-and-ir-optional-classroom-course`
                                                                          : CourseID === '5cc30889-6e13-4008-ac0f-df79a0e0e97a'
                                                                            ? `/political-science-and-ir-optional-online-course`
                                                                            : CourseID === 'c8c46cf8-f7d7-4cab-9251-8a962df982b9'
                                                                              ? `/environment-module-classroom`
                                                                              : CourseID === '2519ab6f-113f-43c3-ab18-0c92cebd4bee'
                                                                                ? `/environment-module-online`
                                                                                : CourseID === '6b66095d-eded-4a16-a456-b84683c00372'
                                                                                  ? `/science-technology-classroom`
                                                                                  : CourseID === '23bcdea2-5aac-45c6-8b78-ccf1feb4bec1'
                                                                                    ? `/science-technology-online`



                                                                                    : `/course-detail/${DisplayName}/${CourseID}`
          }
          className="image_wrap"
        >
          <img src={image || CourseNote} alt={DisplayName} loading="lazy" />
        </Link>
        <div className="card_content">
          <Link
            //to={`/course-detail/${DisplayName}/${CourseID}`}
            to={
              CourseID === '7f77e12b-c186-4934-9bfc-abf3f3ff4a28'
                ? `/courses/modular-courses/NCERT-foundation-course`
                : CourseID === 'f64976d1-9cda-45a2-aa84-13fc66534315'
                  ? `/courses/psir-courses/psir-optional-classroom-course`
                  : CourseID === 'ee52f2c9-6e82-4c89-b6ca-01f13b6335e9'
                    ? `/courses/psir-courses/psir-answer-writing-module`
                    : CourseID === '2e87fe0b-1f18-4aad-aac8-ad3fb389c0e2'
                      ? `/courses/psir-courses/psir-optional-online-course`
                      : CourseID === '5457a2b8-6314-4b32-8c61-0d750382104e'
                        ? `/courses/psir-courses/psir-on-demand-tablet-course`
                        : CourseID === '280e4431-6460-463c-aace-081bc8c4cce7'
                          ? `/courses/psir-courses/psir-postal-correspondence-course`
                          : CourseID === '22f0178d-e5be-4d1d-a524-ff247f882c47'
                            ? `/history-optional-classroom-course`
                            : CourseID === 'd22dea14-d7ce-4e06-a972-f9c3ef6518a3'
                              ? `/history-optional-online-course`
                              : CourseID === '56801f1b-ff6d-407e-b4a6-3a53d1f3a7b0'
                                ? `/courses/sociology-courses/sociology-optional-classroom-course`
                                : CourseID === '7e316a86-a888-4794-aef5-b4a69766e825'
                                  ? `/courses/sociology-courses/sociology-optional-online-course`
                                  : CourseID === '31547019-950b-41a1-9d38-3b9a455b0904'
                                    ? `/courses/sociology-courses/sociology-on-demand-tablet-course`
                                    : CourseID === '9be8b42a-ea81-4f5b-8cb5-18b0e57b1c3e'
                                      ? `/courses/sociology-courses/sociology-test-series`
                                      : CourseID === '0d04b1e8-4b67-44aa-8a54-afdafae1d7cf'
                                        ? `/courses/gs-courses/gs-foundation-classroom-course`
                                        : CourseID === '2269626d-c63f-4cdf-b270-1285c4c3f11e'
                                          ? `/courses/gs-courses/gs-foundation-online-course`
                                          : CourseID === 'a5db0ae6-f2b0-4de6-a4ac-356262aea68d'
                                            ? `/courses/gs-courses/qep-gs-crash-course-classroom`
                                            : CourseID === 'b0f96a3d-250a-4769-bdff-3e223df65efd'
                                              ? `/courses/gs-courses/qep-gs-crash-course-online`
                                              : CourseID === '06f634ce-d49a-4327-bd9e-fc412d3f0e48'
                                                ? `/courses/gs-courses/Integrated-GS-and-PSIR-Optional-Classroom-Course`
                                                : CourseID === '66a37d9f-abd6-4822-b56d-559db8a9cc63'
                                                  ? `/courses/gs-courses/Integrated-GS-and-Sociology-Optional-Classroom-Course`
                                                  : CourseID === '69204890-d049-4521-9f0d-594b1d624d4c'
                                                    ? `/Integrated-GS-and-History-Optional-Classroom-Course`
                                                    : CourseID === 'dbb6e35d-f703-4778-8157-6b3c89ecb343'
                                                      ? `/courses/gs-courses/Integrated-GS-and-PSIR-Optional-Online-Course`
                                                      : CourseID === 'df9db5b3-e8f1-428e-885c-68c91747e9c0'
                                                        ? `/courses/gs-courses/Integrated-GS-and-Sociology-Optional-Online-Course`
                                                        : CourseID === '84f8cf5d-482e-42ec-a49a-b00f434ecf57'
                                                          ? `/Integrated-GS-and-History-Optional-Online-Course`
                                                          : CourseID === 'bd04e35a-8620-465e-bc4a-b2b516cdbfb4'
                                                            ? `/courses/modular-courses/CSAT-foundation-course`
                                                            : CourseID === '53a90166-3367-45b1-b950-70bc3f41187b'
                                                              ? `/courses/modular-courses/essay-program`
                                                              : CourseID === '6b3ff0a9-49c7-42ce-a703-6274bc2d3dae'
                                                                ? `/gs-mentorship-program`
                                                                : CourseID === '5cf9d9f8-a8c9-4c5a-b403-1ed1cdcd4c2b'
                                                                  ? `/gs-mentorship-online-program`
                                                                  : CourseID === '28e68130-8e23-4893-97e1-54880dd2e031'
                                                                    ? `/psir-mentorship-program`
                                                                    : CourseID === '03d608ab-5ae5-4839-b736-591c0ac0bcea'
                                                                      ? `/psir-optional-video-batch`
                                                                      : CourseID === '1c5e7800-9a77-4e74-9ba9-b177377db789'
                                                                        ? `/polity-pyq-course`
                                                                        : CourseID === '6211b29a-1754-4116-a255-6a02e196cdbc'
                                                                          ? `/psir-mentorship-online-program`
                                                                          : CourseID === '77181a27-eaeb-44aa-8164-e8163ef71633'
                                                                            ? `/political-science-and-ir-optional-classroom-course`
                                                                            : CourseID === '5cc30889-6e13-4008-ac0f-df79a0e0e97a'
                                                                              ? `/political-science-and-ir-optional-online-course`
                                                                              : CourseID === 'c8c46cf8-f7d7-4cab-9251-8a962df982b9'
                                                                                ? `/environment-module-classroom`
                                                                                : CourseID === '2519ab6f-113f-43c3-ab18-0c92cebd4bee'
                                                                                  ? `/environment-module-online`
                                                                                  : CourseID === '6b66095d-eded-4a16-a456-b84683c00372'
                                                                                    ? `/science-technology-classroom`
                                                                                    : CourseID === '23bcdea2-5aac-45c6-8b78-ccf1feb4bec1'
                                                                                      ? `/science-technology-online`


                                                                                      : `/course-detail/${DisplayName}/${CourseID}`
            }
          >
            <h5>{DisplayName}</h5>
          </Link>
          <ul>
            <li>
              <img src={Pageimg} alt="" loading="lazy" />
              {Platform === "Both" ? "Hybrid" : Platform || "N/A"}
            </li>
            {/* <li>
              <img src={TimeMachineimg} alt="" loading="lazy" />
              {momentDateFormat(StartDate)}
            </li> */}
            <li>
              <img src={TimeMachineimg} alt="" loading="lazy" />
              {isOnDemand ? moment().format("DD MMM YYYY") : momentDateFormat(StartDate)}
            </li>
          </ul>
          {!courseAccess(StartDate) && (
            <span className="upcomingTag">Upcoming</span>
          )}

          {/* <span
            className={`tagimg ${bookmark ? "_mark" : ""}`}
            onClick={() => setBookmark(!bookmark)}
          >
            {bookmark ? <BsBookmarkFill /> : <BsBookmark />}
          </span> */}
        </div>
      </div>
    </>
  );
};

export default CourseCard;
