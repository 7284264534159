import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { BsFillPlayFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import ListCourseSkeleton from "components/skeleton/ListCourseSkeleton";
import LectureNotFound from "components/Not-Found/LectureNotFound";
import {
  countArray,
  momentDateFormat,
  timeFormatWith12Hour,
  lectureAccess,
  lectureIsStarted,
  lectureIsExpired,
} from "config/utils";
import { noteByLectureView } from "services/lecture";
import { useCourse } from "context/context";
import { AiOutlineDownload, AiOutlineSearch } from "react-icons/ai";
import { errorResponse, failResponse, normalEncryptData } from "config/config";
import useDebounce from "hooks/useDebounce";
import Pagination from "react-js-pagination";
import { getSignedUrl } from "services/master";
const LectureByNotes = () => {
  const { course } = useCourse();
  const [loading, setLoading] = useState(false);
  const [listLectures, setListLectures] = useState([]);
  const [total, setTotal] = useState(0);
  const [activePage, setActivePage] = useState(1);

  const [search, setSearch] = useState("");
  const debounceValue = useDebounce(search, 300);
  const navigate = useNavigate();

  const [submitLoading, setSubmitLoading] = useState(false);


  React.useEffect(() => {
    if (course.courseId) {
      setActivePage(1);  // Reset page to 1 when courseId changes
    }
  }, [course.courseId]);

  
  // get course lectures

  const getLecturesList = async (
    courseId = course.courseId,
    search = debounceValue,
    page = activePage  // Default to activePage, which will be 1 after courseId change
  ) => {
    try {
      setLoading(true);
      const res = await noteByLectureView({
        course_id: courseId,
        search,
        page,  // Use the current value of activePage, which defaults to 1 after courseId change
      });
  
      if (res.code === 200) {
        setListLectures(res.data.lectureByNotes.rows || []);
        setTotal(res.data.lectureByNotes.count || 0);
        setLoading(false);
      } else {
        failResponse(res);
        setLoading(false);
      }
    } catch (err) {
      errorResponse(err);
      setLoading(false);
    }
  };
  
  // const getLecturesList = async (
  //   courseId = course.courseId,
  //   search = debounceValue,
  //   page = activePage
  // ) => {
  //   try {
  //     setLoading(true);
  //     const res = await noteByLectureView({
  //       course_id: courseId,
  //       search,
  //       page,
  //     });

  //     if (res.code === 200) {
  //       setListLectures(res.data.lectureByNotes.rows || []);
  //       setTotal(res.data.lectureByNotes.count || 0);
  //       setLoading(false);
  //     } else {
  //       failResponse(res);
  //       setLoading(false);
  //     }
  //   } catch (err) {
  //     errorResponse(err);
  //     setLoading(false);
  //   }
  // };

  // navigation handler
  const navigationHandler = (id, startDate, endDate, time, file) => {
    if (!course.coursePlatformEnabled) {
      toast.error(
        "Lectures of Tablet platform or Mobile platform cannot be seen on website "
      );
      return;
    }

    // if (!file) {
    //   toast.error("This lecture doesn't have video");
    //   return;
    // }
    if (!file) {
      return null;
    }
    if (lectureIsStarted(startDate, time)) {
      toast.error("Lecture is not started");
      return;
    }
    if (lectureIsExpired(endDate, time)) {
      toast.error("Lecture is expired");
      return;
    }
    if (lectureAccess(startDate, endDate, time)) {
      navigate(`/dashboard/lecture/detail/${normalEncryptData(id, "route")}`, {
        state: { auth: true },
      });
    }
  };

  // notes download handler
  // const notesDownloadHandler = async (notes, startDate, endDate, time) => {
  //   if (lectureAccess(startDate, endDate, time)) {
  //     setSubmitLoading(true);
  //     window.open(await getSignedUrl({ pdfUrl: notes }));
  //     setSubmitLoading(false);
  //   } else {
  //     toast.error("you can't download notes");
  //   }
  // };

  const notesDownloadHandler = async (notes) => {
    setSubmitLoading(true);
    window.open(await getSignedUrl({ pdfUrl: notes }));
    setSubmitLoading(false);
  };

  // ----------------------- getting courses detail data -------------------------//
  React.useLayoutEffect(() => {
    if (course.courseId) {
      getLecturesList();
    }
    // eslint-disable-next-line
  }, [course, debounceValue, activePage]);

  return (
    <>
    <div className="pagination_search_class">
        <div className="d-flex flex-column flex-md-row justify-content-between py-2 align-items-center">
          {/* Pagination on the top for mobile and left side for desktop */}
          <div className="d-flex mb-2 mb-md-0">
            {total > 10 ? (
              <div className="pagination_wrap">
                <Pagination
                  activePage={activePage}
                  previousLabel={"previous"}
                  nextLabel={"next"}
                  itemsCountPerPage={10}
                  totalItemsCount={total}
                  pageRangeDisplayed={5}
                  activeLinkClass={"active"}
                  onChange={(page) => setActivePage(page)}
                />
              </div>
            ) : null}
          </div>

          {/* Search bar on the bottom for mobile and right side for desktop */}
          <div className="search_bar_wrap d-flex" style={{ width: "275px" }}>
            <div className="search_bar">
              <input
                type="search"
                placeholder="Search"
                className="input"
                value={search}
                onChange={({ target }) => setSearch(target.value)}
              />
              <AiOutlineSearch />
            </div>
          </div>
        </div>
      </div>
      <div className="daily_lecture">
        <div className="meeting_content">
          <ul>
            {/* ************ Loading Component *********** */}
            <li className="lecture-card heading">
              <h6>Lecture Name</h6>
              {/* <div className="play_btn">
                <b>Video</b>
              </div> */}

              <div className="date">
                <b>Start Date</b>
              </div>
              <div className="date">
                <b>End Date</b>
              </div>

              <div className="date">
                <b>Duration</b>
              </div>
              <div className="date">
                <b>Class Notes</b>
              </div>
            </li>
            {loading && (
              <>
                {countArray(8).map((data) => {
                  return (
                    <li key={data} className="p-0">
                      <ListCourseSkeleton height={90} />
                    </li>
                  );
                })}
              </>
            )}
            {/* iterate lectures */}
            {!loading &&
              listLectures.length > 0 &&
              listLectures.map(
                ({
                  LectureID,
                  LectureTitle,
                  Duration,
                  Notes,
                  startDate,
                  endDate,
                  lecturetime,
                  LectureDetail,
                }) => {
                  return (
                    <li
                      key={LectureID}
                      // className={`lecture-card ${
                      //   !lectureAccess(startDate, endDate, lecturetime) &&
                      //   "disabled"
                      // }`}
                      className="lecture-card"
                    >
                      {lectureAccess(startDate, endDate, lecturetime) ? (
                      <>
                       <h6
                        onClick={() =>
                          navigationHandler(
                            LectureID,
                            startDate,
                            endDate,
                            lecturetime,
                            LectureDetail?.VideoFile
                          )
                        }
                        style={{cursor:"default"}}
                      >
                        {LectureTitle || "N/A"}
                      </h6>
                      {/* <div className="play_btn">
                        <span
                          onClick={() =>
                            navigationHandler(
                              LectureID,
                              startDate,
                              endDate,
                              lecturetime,
                              LectureDetail?.VideoFile
                            )
                          }
                        >
                          <span className="play-icon">
                            <BsFillPlayFill />
                          </span>
                        </span>
                      </div> */}

                      <div className="date">
                        <b>{`${momentDateFormat(startDate)}`}</b>
                        <br />
                        <b>{`${timeFormatWith12Hour(lecturetime)}`}</b>
                      </div>
                      <div className="date red">
                        <b>{`${momentDateFormat(endDate)}`}</b>
                        <br />
                        <b>{`${timeFormatWith12Hour(lecturetime)}`}</b>
                      </div>

                      <div className="date">
                        <b>{Duration} mins</b>
                      </div>
                      <div className="date">
                        {Notes ? (
                          <button
                            className="dashboard_button"
                            onClick={() =>
                              notesDownloadHandler(
                                Notes
                                // startDate,
                                // endDate,
                                // lecturetime
                              )
                            }
                            // disabled={
                            //   !lectureAccess(startDate, endDate, lecturetime) ||
                            //   !Notes ||
                            //   submitLoading
                            // }
                          >
                            <AiOutlineDownload /> Download
                          </button>
                        ) : (
                          <b>N/A</b>
                        )}
                      </div>
                      
                      </>
                      ):(
                      <>
                       <h6
                       style={lectureAccess(startDate, endDate, lecturetime) ? {} : { color: 'darkgray',cursor:"default" }}
                        onClick={() =>
                          navigationHandler(
                            LectureID,
                            startDate,
                            endDate,
                            lecturetime,
                            LectureDetail?.VideoFile
                          )
                        }
                      >
                        {LectureTitle || "N/A"}
                      </h6>
                      <div className="play_btn disabled">
                        <span
                          onClick={() =>
                            navigationHandler(
                              LectureID,
                              startDate,
                              endDate,
                              lecturetime,
                              LectureDetail?.VideoFile
                            )
                          }
                        >
                          <span className="play-icon disabled">
                            <BsFillPlayFill />
                          </span>
                        </span>
                      </div>

                      <div className="date disabled">
                        <b>{`${momentDateFormat(startDate)}`}</b>
                        <br />
                        <b>{`${timeFormatWith12Hour(lecturetime)}`}</b>
                      </div>
                      <div className="date disabled">
                        <b>{`${momentDateFormat(endDate)}`}</b>
                        <br />
                        <b>{`${timeFormatWith12Hour(lecturetime)}`}</b>
                      </div>

                      <div className="date disabled">
                        <b>{Duration} mins</b>
                      </div>
                      <div className="date">
                        {Notes ? (
                          <button
                            className="dashboard_button"
                            onClick={() =>
                              notesDownloadHandler(
                                Notes
                                // startDate,
                                // endDate,
                                // lecturetime
                              )
                            }
                            // disabled={
                            //   !lectureAccess(startDate, endDate, lecturetime) ||
                            //   !Notes ||
                            //   submitLoading
                            // }
                          >
                            <AiOutlineDownload /> Download
                          </button>
                        ) : (
                          <b>N/A</b>
                        )}
                      </div>
                      </>
                      )}

                    </li>
                  );
                }
              )}

            {/* Not Found */}
            {!loading && listLectures.length <= 0 && (
              <>
                <LectureNotFound title="Lectures" />
              </>
            )}
          </ul>
        </div>
      </div>
    </>
  );
};

export default LectureByNotes;
