import React, { useRef, useState } from "react";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import { BsArrowRight } from "react-icons/bs";
import { MdKeyboardArrowDown } from "react-icons/md";
import { Link, NavLink } from "react-router-dom";
import { Foundation } from "assets/icons/index";
import { charLimit } from "config/utils";

const FreeResourcesMenu = () => {
  const [selectedCategory, setSelectedCategory] = useState({
    id: "0",
    name: "About UPSC",
    key: "FRAboutUPSC",
  });

  const [onHover, setOnHover] = useState(false);
  const menuRef = useRef();

  // Hardcoded category data
  const allCategories = [
    { id: "0", name: "About UPSC", key: "FRAboutUPSC" },
    { id: "1", name: "Prelims", key: "FRPrelims" },
    { id: "2", name: "Mains", key: "FRMains" },
    // { id: "3", name: "Optional", key: "FROptional" },
    // { id: "4", name: "Interview", key: "FRInterview" },
  ];

  // Hardcoded subcategory data
  const allSubCategory = [
    {
      //
      id: "01",
      name: "Civil Services Exam",
      subkey: "FRAboutUPSCContent",
      categoryKey: "FRAboutUPSC",
      description: "Explore expert guidance and curated content to crack the UPSC exam.",
    },
    {
      id: "02",
      name: "Eligibity",
      subkey: "FRAboutUPSCEligibility",
      categoryKey: "FRAboutUPSC",
      description: "Understand the detailed eligibility criteria to appear for the UPSC exam.",
    },
    {
      id: "11",
      name: "Syllabus",
      subkey: "FRPrelimsSyllabus",
      categoryKey: "FRPrelims",
      description: "Complete syllabus for prelims exams.",
    },
    {
      id: "12",
      name: "PYQ Prelims",
      subkey: "FRPrelimsPYQPrelims",
      categoryKey: "FRPrelims",
      description: "Previous year prelims questions.",
    },
    {
      id: "13",
      name: "Prelims Strategy",
      subkey: "FRPrelimsPrelimsStrategy",
      categoryKey: "FRPrelims",
      description: "Effective prelims strategies.",
    },
    // {
    //   id: "14",
    //   name: "Hot Topics for Prelims",
    //   subkey: "FRPrelimsHotTopicsforPrelims",
    //   categoryKey: "FRPrelims",
    //   description: "Trending prelims topics.",
    // },
    {
      id: "21",
      name: "Syllabus",
      subkey: "FRMainsSyllabus",
      categoryKey: "FRMains",
      description: "Complete mains syllabus.",
    },
    {
      id: "22",
      name: "GS-I",
      subkey: "FRMainsGS1Overview",
      categoryKey: "FRMains",
      description: "Complete mains GS-I.",
    },
    {
      id: "23",
      name: "GS-II",
      subkey: "FRMainsGS2Overview",
      categoryKey: "FRMains",
      description: "Complete mains GS-II.",
    },
    {
      id: "24",
      name: "GS-III",
      subkey: "FRMainsGS3Overview",
      categoryKey: "FRMains",
      description: "Complete mains GS-III.",
    },
    {
      id: "25",
      name: "GS-IV",
      subkey: "FRMainsGS4Overview",
      categoryKey: "FRMains",
      description: "Complete mains GS-IV.",
    },
    {
      id: "27",
      name: "PSIR Paper 1",
      subkey: "FRMainsPSIRPaper1",
      categoryKey: "FRMains",
      description: "Complete Paper 1 Syllabus",
    },
    {
      id: "28",
      name: "PSIR Paper 2",
      subkey: "FRMainsPSIRPaper2",
      categoryKey: "FRMains",
      description: "Complete Paper 2 Syllabus",
    }
    // {
    //   id: "22",
    //   name: "Essay",
    //   subkey: "FRMainsEssay",
    //   categoryKey: "FRMains",
    //   description: "Mains essay syllabus.",
    // },
    // {
    //   id: "23",
    //   name: "PYQ Mains",
    //   subkey: "FRMainsPYQMains",
    //   categoryKey: "FRMains",
    //   description: "Previous year mains questions.",
    // },
    // {
    //   id: "24",
    //   name: "Hot Topics for Mains",
    //   subkey: "FRMainsHotTopicsforMains",
    //   categoryKey: "FRMains",
    //   description: "Trending mains topics.",
    // },
    // {
    //   id: "25",
    //   name: "GS I",
    //   subkey: "FRMainsGS1",
    //   categoryKey: "FRMains",
    //   description: "General Studies Paper I topics.",
    // },
    // {
    //   id: "26",
    //   name: "GS II",
    //   subkey: "FRMainsGS2",
    //   categoryKey: "FRMains",
    //   description: "General Studies Paper II topics.",
    // },
    // {
    //   id: "27",
    //   name: "GS III",
    //   subkey: "FRMainsGS3",
    //   categoryKey: "FRMains",
    //   description: "General Studies Paper III topics.",
    // },
    // {
    //   id: "28",
    //   name: "GS IV",
    //   subkey: "FRMainsGS4",
    //   categoryKey: "FRMains",
    //   description: "General Studies Paper IV topics.",
    // },
    // {
    //   id: "31",
    //   name: "PSIR",
    //   subkey: "FROptionalPSIR",
    //   categoryKey: "FROptional",
    //   description: "Political Science & IR topics.",
    // },
    // {
    //   id: "32",
    //   name: "History",
    //   subkey: "FROptionalHistory",
    //   categoryKey: "FROptional",
    //   description: "Key topics in History.",
    // },
    // {
    //   id: "33",
    //   name: "Sociology",
    //   subkey: "FROptionalSociology",
    //   categoryKey: "FROptional",
    //   description: "Important Sociology topics.",
    // },
    // {
    //   id: "34",
    //   name: "Geography",
    //   subkey: "FROptionalGeography",
    //   categoryKey: "FROptional",
    //   description: "Core Geography topics.",
    // },
    // {
    //   id: "41",
    //   name: "Topper's Interview",
    //   subkey: "FRInterviewToppersInterview",
    //   categoryKey: "FRInterview",
    //   description: "Interviews with top exam performers.",
    // },
    // {
    //   id: "42",
    //   name: "Top Interviews",
    //   subkey: "FRInterviewTopInterviews",
    //   categoryKey: "FRInterview",
    //   description: "Best interviews with top candidates.",
    // },
    // {
    //   id: "43",
    //   name: "Toppers List",
    //   subkey: "FRInterviewToppersList",
    //   categoryKey: "FRInterview",
    //   description: "List of top exam rankers.",
    // },
    // {
    //   id: "44",
    //   name: "Panel List",
    //   subkey: "FRInterviewPanelList",
    //   categoryKey: "FRInterview",
    //   description: "Details of interview panel members.",
    // }


  ];



  return (
    <>
      <li
        className={`has-menu ${onHover ? "active" : ""}`}
        ref={menuRef}
        onMouseEnter={() => setOnHover(true)}
        onMouseLeave={() => setOnHover(false)}
      >
        <NavLink to="" className="nav_link">
          Free Resources
          <span className="icon">
            <MdKeyboardArrowDown />
          </span>
        </NavLink>

        <div className="megamenu course">
          <Tab.Container id="left-tabs-example">
            <Row>
              <Col sm={3} className="menu_left">
                <Nav variant="pills" className="flex-column">
                  {allCategories &&
                    allCategories.map(({ name, id, key }) => (
                      <Nav.Item
                        key={id}
                        onClick={() => setSelectedCategory({ id, name, key })}
                      >
                        <Nav.Link
                          className={`nav_item ${id === selectedCategory.id ? "active" : ""
                            }`}
                        >
                          <img src={Foundation} alt="" loading="lazy" />
                          <p>
                            <b>{name}</b>
                          </p>
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                </Nav>
              </Col>

              {selectedCategory?.id && (
                <Col sm={9} className="menu_right">
                  <div className="megamenu_content row">
                    {/* Filtered subcategory listing */}
                    {allSubCategory
                      .filter(sub => sub.categoryKey === selectedCategory.key)
                      .map(({ id, name, subkey, description }) => (
                        <div className="col" key={id}>
                          <h5>
                            <Link
                              onClick={() =>
                                menuRef.current.classList.remove("active")
                              }
                              to={`/freeresources/${subkey}`}
                            >
                              {name}
                            </Link>
                            <span style={{fontSize:"16px"}}>{charLimit(description)}</span>
                          </h5>
                        </div>
                      ))}

                    <div className="button_wrap">
                      <Link
                        onClick={() => menuRef.current.classList.remove("active")}
                        to={`/freeresources/${selectedCategory.key}`}
                        state={{ from: "FRMenu" }}
                        className="button"
                      >
                        {selectedCategory?.id === "0"
                          ? selectedCategory?.name
                          : `About ${selectedCategory?.name}`}
                        <span className="icon">
                          <BsArrowRight />
                        </span>
                      </Link>
                    </div>

                  </div>
                </Col>
              )}
            </Row>
          </Tab.Container>
        </div>
      </li>
    </>
  );

};

export default FreeResourcesMenu;
