import { decryptData, encryptData } from "config/config";
import { instance as axios } from "config/axiosInstance";
import { getBrowserName, isLoggedIn } from "config/utils";


// export const AddSolutionDownload = async (data = {}) => {
//   return decryptData(
//     await axios.post(`/AddSolutionDownload`, {
//       reqData: encryptData({
//         ...data,
//         platformType: getBrowserName(),
//       }),
//     })
//   );
// };

export const AddSolutionDownload = async (data) => {
    return decryptData(
      await axios.post(`/AddSolutionDownload`, {
        reqData: encryptData({ ...data, platformType: getBrowserName() }),
      })
    );
  };