import React, { useEffect, useState } from "react";
import "../Syllabus.scss";
import { useNavigate } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";
import { errorResponse, failResponse } from "config/config";

const GS2 = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Initial mobile check
  const [content, setContent] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    setContent(`<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <style>
        .Eligibilityh3 {
            font-size: 18px;
            margin-bottom: 10px;
            text-align: center;
        }

        .Eligibilityp {
            margin-bottom: 8px;
            font-size: 17px;
            font-weight: 500;
            margin-top: 10px;
        }
		body{
		line-height:35px;
		}
		.title{
		color:#00239c;
    font-size: 17px;
		}
		.title1{
		color:#D2042D;
    font-size: 17px;
		}
		.pagetitle{
		color:#00239c;
		text-align: center;font-size: 26px;
		}
    .ulgs{
    font-size: 17px;
    }
    .ligs{
        font-size: 17px;
    }
    </style>
</head>
<body>
    <h3 class="pagetitle">UPSC Mains General Studies Paper 2 Overview</h3><br/>
    <p class="Eligibilityp"><strong>General Studies Paper 2</strong> in the UPSC Mains exam is one of the core components shaping the preparation of UPSC aspirants. This paper covers a broad spectrum of topics segmented into distinct subjects like the Indian Constitution and Polity, Governance, Social Justice and International Relations. Each subject requires a unique approach and diverse source materials for effective preparation.</p>
    
    <strong class="title1">Question Format</strong>
    <ul class="ulgs" style="list-style-type: disc; margin-left: 20px;padding-left:20px;">
        <li class="ligs"><strong>Number of Questions:</strong> 20 compulsory questions</li>
        <li class="ligs"><strong>Languages:</strong> Questions are presented in both Hindi and English. Answers must be written in the language specified during the application process other languages will not be evaluated.</li>
        <li class="ligs"><strong>Total Marks:</strong> 250 marks</li>
    </ul>
    <strong class="title1">Word Limits</strong>
    <ul class="ulgs" style="list-style-type: disc; margin-left: 20px;padding-left:20px;">
         <li class="ligs"><strong>10-mark Questions:</strong> Maximum of 150 words</li>
        <li class="ligs"><strong>15-mark Questions:</strong> Maximum of 250 words</li>
    </ul>
    <strong class="title1">Key Subject Areas</strong>
        <br/>
          <strong class="title" style="padding-left:20px">1. INDIAN CONSTITUTION AND POLITY</strong><br/>
          <strong class="title" style="padding-left:32px">Major Topics:</strong>
          <ul class="ulgs" style="list-style-type: circle; margin-left: 20px;padding-left:60px;">
                <li class="ligs">Indian Constitution: Historical underpinnings & evolution; Features, amendments, significant provisions, basic structure;</li>
                <li class="ligs">Comparison of Indian constitutional scheme with other countries</li>
                <li class="ligs">Functions and Responsibilities of the Union and the States, Issues and Challenges Pertaining to the Federal Structure, Devolution of Powers and Finances up to Local Levels and Challenges therein.</li>
                <li class="ligs">Executive (structure, organization, functioning);</li>
                <li class="ligs">Ministries and Departments (of Union and State govts.)</li>
                <li class="ligs">Union and State Legislatures (structure, functioning, conduct of business, powers & privileges; issues therein);</li>
                <li class="ligs">Salient features of the Representation of People’s Act.</li>
                <li class="ligs">Separation of Powers between various organs Dispute Redressal Mechanisms and Institutions.li>
                <li class="ligs">Judiciary (structure, organisation functioning)</li>
                <li class="ligs">Functions & responsibilities of the Union and the States; issues and challenges of federal structure;</li>
                <li class="ligs">Devolution of powers and finances to local levels; challenges therein.</li>
                <li class="ligs">Appointment to various Constitutional posts; Constitutional Bodies (powers, functions and responsibilities); Statutory, Regulatory and Quasi-judicial bodies</li>
           </ul><br/>
          
           <strong class="title" style="padding-left:32px">Sources and Approach:</strong>
          <ul class="ulgs" style="list-style-type: circle; margin-left: 20px;padding-left:60px;">
                <li class="ligs"><em>NCERT Textbooks:</em> Classes IX and XI are fundamental for building a strong physical and human geography base.</li>
                <li class="ligs">Introduction to the Constitution of India: DD Basu</li>
                <li class="ligs">Our Parliament: Subhash C. Kashyap</li>
                <li class="ligs">Indian Polity: M. Laxmikant</li>
                <li class="ligs">Regular newspaper: <em>The Indian Express or The Hindu</em></li>
           </ul><br/>
          
           <strong class="title" style="padding-left:32px">Exam Analysis:</strong>
          <ul class="ulgs" style="list-style-type: circle; margin-left: 20px;padding-left:60px;">
               <li class="ligs"><strong>Basic Structure:  </strong> Always in consistent, this topic has been in focus every year especially in recent times like 2024 (30 marks) and 2023 (25 marks).</li>
                <li class="ligs"><strong>Comparison:</strong>There have been questions from this section in recent years after 2018, reflecting a dynamic trend in India’s foreign policy and relations. </li>
                <li class="ligs"><strong>Legislatures and Elections:</strong>Both these topics have had less focus. However there has been an inconsistent focus over the years, reflecting a selective approach to these aspects within the syllabus.</li>
                <li class="ligs"><strong>Federalism and Local Governance: </strong>Consistently receiving attention, with an increased focus in 2024(25 marks), 2023 (40 marks) and 2020 (40 marks), indicating its core importance in geographical studies.</li>
          </ul><br/>
          
          <strong class="title" style="padding-left:20px">2.	SOCIAL JUSTICE</strong>
          <br/>
          <strong class="title" style="padding-left:35px">Major Topics:</strong>
          <ul class="ulgs" style="list-style-type: circle; margin-left: 20px;padding-left:60px;">
                <li class="ligs">Welfare Schemes (centre, states; performance, mechanisms, laws, institutions and bodies constituted for protection of vulnerable sections);</li>
                <li class="ligs">Poverty and hunger issues.</li>
                <li class="ligs">Social Sector & Social Services (health, education, human resources – issues in development, management)</li>
                <li class="ligs">Government Policies & Interventions for development of various sectors (issues in their design, implementation)</li>
           </ul><br/>
          
           <strong class="title" style="padding-left:35px">Sources and Approach:</strong>
          <ul class="ulgs" style="list-style-type: circle; margin-left: 20px;padding-left:60px;">
                <li class="ligs">Daily newspaper and Government Schemes</li>
           </ul><br/>
          
           <strong class="title" style="padding-left:35px">Exam Analysis:</strong>
           <ul class="ulgs" style="list-style-type: circle; margin-left: 20px;padding-left:60px;">
           <li class="ligs"><strong>Welfare Schemes:</strong> There has in an inconsistency in these topics. Questions are generally asked every 2-3 years signifying the diversion and focus. </li>
           <li class="ligs"><strong>Education, Health and HDI: :</strong> These topics hold significance in India’s development agenda. Therefore, questions from these sections every year especially 2016 (37.5 marks), 2020 (25 marks) and 2023 (40 marks) highlights the focus of government and significance towards these themes.</li>
           <li class="ligs"><strong>Poverty and Hunger Issues:  </strong> This category is generally static in nature with questions being asked regularly like 2018 (30 marks), 2022 (30 marks), 2022 (25 marks) and 2024 (10 marks). </li>
           </ul><br/>
           
           <strong class="title" style="padding-left:20px">3.	GOVERNANCE</strong><br>
           <strong class="title" style="padding-left:35px">Major Topics:</strong>
           <ul class="ulgs" style="list-style-type: circle; margin-left: 20px;padding-left:60px;">
           <li class="ligs">Important aspects of governance; Transparency and accountability (institutional and other measures); Citizens Charter;</li>
           <li class="ligs">E-Governance (applications, models, successes, limitations, potential).</li>
           <li class="ligs">Development Processes & Development industry (role of NGOs, SHGs, groups & associations, donors, charities, institutional and other stakeholders)</li>
           <li class="ligs">Pressure Groups & Formal, Informal associations (and their role in the polity)</li>
           <li class="ligs">Role of Civil Services in a democracy.</li>
           </ul><br/>
      
           <strong class="title"style="padding-left:35px">Sources and Approach:</strong>
           <ul class="ulgs" style="list-style-type: circle; margin-left: 20px;padding-left:60px;">
           <li class="ligs">ARC Report (Ethics in Governance and Citizen Centric Administration)</li>
           <li class="ligs">Regular reading of <em>Newspapers and Magazines</em> like Economic and Political Weekly (EPW), along with reports from NGOs and international organizations, can provide contemporary insights and data</li>
           </ul><br/>
      
           <strong class="title" style="padding-left:35px">Exam Analysis:</strong>
           <ul class="ulgs" style="list-style-type: circle; margin-left: 20px;padding-left:60px;">
           <li class="ligs"><strong>Transparency, Accountability and e-Governnce:</strong> These sections are an important and core areas of focus of the government as well as the UPSC, thereby being asked almost every year.</li>
           <li class="ligs"><strong>NGOs, Pressure Groups and Civil Services: </strong> These topics are relevant every day for the good governance of the country and therefore dynamic in nature with questions being asked every year.</li>
           </ul><br/>

           <strong class="title" style="padding-left:20px">4.	INTERNATIONAL RELATIONS</strong><br>
           <strong class="title" style="padding-left:35px">Major Topics:</strong>
           <ul class="ulgs" style="list-style-type: circle; margin-left: 20px;padding-left:60px;">
           <li class="ligs">India and its Neighbourhood (relations)</li>
           <li class="ligs">Effect of Policies & Politics of Developed and Developing countries on India (India’s interests, diaspora)</li>
           <li class="ligs">Important International institutions, agencies, for a (structure, mandate); </li>
           <li class="ligs">Bilateral, Regional, Global groupings & Agreements (involving and/or affecting India)</li>
           </ul><br/>
      
           <strong class="title"style="padding-left:35px">Sources and Approach:</strong>
           <ul class="ulgs" style="list-style-type: circle; margin-left: 20px;padding-left:60px;">
           <li class="ligs"><strong><em>Websites: </em></strong>Ministry of External Affairs (MEA), Institute of Defence Studies and Analyses (IDSA) and Lok Sabha website.</li>
           <li class="ligs">Rajya Sabha YouTube Videos</li>
           <li class="ligs">Regular Newspaper: <em>The Hindu or Indian Express</em></li>
           </ul><br/>
      
           <strong class="title" style="padding-left:35px">Exam Analysis:</strong>
           <ul class="ulgs" style="list-style-type: circle; margin-left: 20px;padding-left:60px;">
           <li class="ligs"><strong>India and its Neighbours: </strong>There have been inconsistent focus in this section with questions being asked in recent times after a break for 3 years. This reflects the changing dynamics in government’s neighbourhood policies and relations.</li>
           <li class="ligs"><strong>India’s interests and diaspora: </strong>This part have forever been in focus and due to its significance in India’s diplomacy and rising global standard. Except 2021 and 2014, regular questions have been asked.</li>
           <li class="ligs"><strong>International groupings and agreements: </strong>Indicating it relevance in international arena, questions have been asked from this section every year especially 2023 (40 marks) and 2022 (40 marks).</li>
           </ul><br/>

    </body>
</html>
`);
  });

  useEffect(() => {
    // Function to fetch the content
    const fetchUPSCcontent = async () => {
      try {
        // const res = await GetUPSCcontent();
        // if (res.code === 200) {
        //   const { htmlcontent } = res.data.content[3];
        //   setContent(htmlcontent);
        // } else {
        //   failResponse(res);
        // }
      } catch (err) {
        errorResponse(err);
      }
    };

    // Function to update isMobile state on window resize
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    fetchUPSCcontent(); // Fetch content on component mount

    window.addEventListener("resize", handleResize); // Listen for window resize
    return () => {
      window.removeEventListener("resize", handleResize); // Clean up listener on unmount
    };
  }, []); // Empty dependency array ensures this runs only once (on mount)

  // Handle back button functionality
  const handleBack = () => {
    navigate("/freeresources/FRMains"); // Use navigate to go back to the specified route
  };

  return (
    <div className="syllabus-container">
      {isMobile && (
        <div className="mobile-back-arrow" onClick={handleBack}>
          <FaChevronLeft style={{ color: "#181822", fontSize: "24px" }} />{" "}
          {/* Font Awesome Arrow Icon */}
        </div>
      )}
      <div className="subsection-prelims-syllabus-container">
      <span onClick={() => navigate("/freeresources/FRMainsGS2Notes")} className="gsnotespan" style={{cursor:"pointer", color:"#0056b3", fontWeight:"600"}}>GS II Notes</span>

        <div dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    </div>
  );
};

export default GS2;
