import { Input, Select } from "components/Form";
import { errorResponse, failResponse } from "config/config";
import { validateEmail } from "config/utils";
import useButtonLoader from "hooks/useButtonLoader";
import useCookie from "hooks/useCookie";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { BsArrowRight } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import logo from "assets/images/logo.svg";
import "./downloadSolution.scss";
import { AddSolutionDownload } from "services/downloadsolution";
import CommonModal from "components/modal-box/CommonModal";

export default function DownloadSolution() {
  localStorage.setItem("welcome-popup", false);
  const [createAccount, setCreateAccount] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isMobileOtpSent, setIsMobileOtpSent] = useState(false);

  const [submitButtonRef, setSubmitLoading] = useButtonLoader(
    "Create Account",
    true
  );

  const navigate = useNavigate();
  const { cookies } = useCookie();
  // signup state
  const [flag, setFlage] = useState(false);
  const [signUpData, setSignUpData] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    mobile: "",
    examId: "",
    signupType: "normal",
    userId: "",
    tnc: false,
  });

  const [errorField, setErrorField] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    examId: "",
    signupType: "normal",
    tnc: false,
  });

  // onchange handler
  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setSignUpData({ ...signUpData, [name]: value });
  };

  //*********************Handel Validation********************************** */
  const handleValidation = () => {
    let errorField = {};
    let formIsValid = true;

    if (!signUpData.name) {
      errorField["name"] = "Please enter full name";
      formIsValid = false;
    }

    if (!signUpData.email) {
      errorField["email"] = "Please enter email";
      formIsValid = false;
    } else if (!validateEmail(signUpData.email)) {
      errorField["email2"] = "Please enter valid email";
      formIsValid = false;
      setIsValidEmail(false);
    }

    if (!signUpData.mobile) {
      errorField["mobile"] = "Please enter mobile No.";
      formIsValid = false;
    } else if (signUpData.mobile.length < 10) {
      errorField["mobile2"] = "Mobile number must be 10 digit";
      formIsValid = false;
    }

    setErrorField(errorField);
    return formIsValid;
  };

  // signup handler
  // const signUpHandler = async (e) => {
  //   e.preventDefault();
  //   if (handleValidation()) {
  //     setSubmitLoading(true);
  //     try {
  //       const { data: ip } = await getUserIp();
  //       const res = await userRegister({
  //         ...signUpData,
  //         device: "Web",
  //         platformType: getBrowserName(),
  //         os: getOSDetail(),
  //         IPaddress: ip.ip,
  //         userType: "login",
  //       });

  //       if (res.code === 200) {
  //         toast.success(res.message);
  //         if (res.message === 'Otp sent successfully') {
  //           setIsMobileOtpSent(true);
  //         }
  //         setSignUpData({ ...signUpData, userId: res.data.UserID });
  //         setSubmitLoading(false);
  //         setCreateAccount(true);
  //       } else {
  //         failResponse(res);
  //         setSubmitLoading(false);
  //       }
  //     } catch (err) {
  //       errorResponse(err);
  //       setSubmitLoading(false);
  //     }
  //   } else {
  //     toast.error("Please Fill All Fields");
  //   }
  // };

  const signUpHandler = async (e) => {
    e.preventDefault();
    if (handleValidation()) {
      setSubmitLoading(true);
      try {
        const res = await AddSolutionDownload({
          name: signUpData.name,
          email: signUpData.email,
          mobile: signUpData.mobile,
        });
        if (res.code === 200) {
          toast.success(res.message);
          setSignUpData({});
          setFlage(true);
          setSubmitLoading(false);
        } else {
          failResponse(res);
          setFlage(false);
          setSignUpData({});
          setSubmitLoading(false);
        }
      } catch (err) {
        errorResponse(err);
        setSubmitLoading(false);
      }
    } else {
      toast.error("Please Fill All Fields");
    }
  };

  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 900,
    autoplay: true,
    autoplaySpeed: 8000,
    fade: true,
    cssEase: "linear",
  };

  //   React.useLayoutEffect(() => {
  //     if (cookies) navigate("/dashboard/home");
  //     // eslint-disable-next-line
  //   }, [cookies]);

  return (
    <>
      <div className="download-solution signup spacing">
        <div className="form_container">
          <div className="content_section">
            <div className="form_section">
              <img src={logo} alt="" className="logo" />
              <form className="form">
                <div className="form_title">
                  <h3>Download Solution </h3>
                </div>
                {!flag && (
                  <>
                    <div className="form_field" style={{textAlign:'left'}}>
                      <label className="label" style={{fontSize:"22px"}}>
                        {"Name"}
                         <span style={{fontSize:"22px"}} className="required mx-1"> *</span>
                      </label>

                      <Input
                        type="text"
                        name="name"
                        value={signUpData.name || ""}
                        onChange={onChangeHandler}
                        onKeyPress={(event) => {
                          if (!/[a-zA-Z\s]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        style={{ fontSize: "20px" }}
                        placeholder="Enter full name"
                        className={`input form-control `}
                        maxLength={50}
                        // label={"Name"}
                        error={errorField.name}
                        required
                      />
                    </div>

                    <div className="form_field" style={{textAlign:'left'}}>
                    <label className="label" style={{fontSize:"22px"}}>
                        {"Email"}
                         <span style={{fontSize:"22px"}} className="required mx-1"> *</span>
                      </label>
                      <Input
                        type="text"
                        name="email"
                        value={signUpData.email || ""}
                        onChange={onChangeHandler}
                        style={
                          errorField.email2
                            ? { border: "1px solid #DD5D59", fontSize: "20px" }
                            : { fontSize: "20px" }
                        }
                        placeholder="Enter Email"
                        className={`input form-control `}
                        maxLength={50}
                        // label={"Email"}
                        error={errorField.email}
                        required
                      />
                      {errorField.email2 && (
                        <div style={{ fontSize: "12px", color: "#DD5D59" }}>
                          {errorField.email2}
                        </div>
                      )}
                    </div>

                    <div className="form_field" style={{textAlign:'left'}}>
                    <label className="label" style={{fontSize:"22px"}}>
                        {"Mobile"}
                         <span style={{fontSize:"22px"}} className="required mx-1"> *</span>
                      </label>
                      <Input
                        type="text"
                        name="mobile"
                        value={signUpData.mobile || ""}
                        onChange={({ target }) =>
                          setSignUpData({
                            ...signUpData,
                            mobile: target.value.replace(/\D/g, ""),
                          })
                        }
                        style={
                          errorField.mobile2
                            ? { border: "1px solid #DD5D59", fontSize: "20px" }
                            : { fontSize: "20px" }
                        }
                        placeholder="Enter your phone number"
                        className={` input form-control `}
                        maxLength={10}
                        // label={"Mobile"}
                        error={errorField.mobile}
                        required
                      />
                      {errorField.mobile2 && (
                        <div style={{ fontSize: "12px", color: "#DD5D59" }}>
                          {errorField.mobile2}
                        </div>
                      )}
                    </div>
                    <div className="button_wrap">
                      <button
                        //   to="#"
                        onClick={signUpHandler}
                        className="button"
                        style={{ fontSize: "20px" }}
                        //   ref={submitButtonRef}
                      >
                        Submit
                        <span className="icon">
                          <BsArrowRight />
                        </span>
                      </button>
                    </div>
                  </>
                )}
                {flag && (
                  <div className="button_wrap" style={{ marginTop: "-30px" }}>
                    <Link
                      to="https://admin.shubhraviraj.in/Model-Answer-All-India-Mock-test-22-March.pdf"
                      className="button"
                      target="_blank"
                      style={{ fontSize: "20px" }}
                      //   ref={submitButtonRef}
                    >
                      Download
                    </Link>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* <CommonModal
        show={flag}
        handleClose={() => setFlage(false)}
        className={"modal-md"}
        title={"Download Solution"}
      >
        <div className="button_wrap" style={{display:"flex",width:"100%",justifyContent:"center"}}>
          <Link
            to="https://admin.shubhraviraj.in/Model-Answer-All-India-Mock-test-22-March.pdf"
            className="button"
            target="_blank"
            //   ref={submitButtonRef}
          >
            Download
          </Link>
        </div>
      </CommonModal> */}
    </>
  );
}
