import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom"; // Import Link for navigation
import { FaChalkboardTeacher, FaUserGraduate } from "react-icons/fa"; // Import icons from react-icons
import "./AboutUPSC.scss"; // Import the CSS file for styling

const AboutUPSC = () => {
  // State to track mobile view
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // State to track grid layout based on screen size
  const [gridTemplateColumns, setGridTemplateColumns] = useState('1fr 1fr'); // Default to 2 columns for desktop

  const updateGridColumns = () => {
    const mobile = window.innerWidth <= 768;
    setIsMobile(mobile); // Check if screen is mobile size
    setGridTemplateColumns(mobile ? '1fr' : '1fr 1fr'); // Set to 1 column for mobile, 2 columns for desktop
  };

  useEffect(() => {
    updateGridColumns(); // Set initial grid columns

    // Attach event listener to handle window resize
    window.addEventListener('resize', updateGridColumns);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateGridColumns);
    };
  }, []);

  // Define the styles for the grid
  const gridStyle = {
    display: 'grid',
    gridTemplateColumns: gridTemplateColumns,
    gap: '20px', // Add spacing between cards
  };

  // Define the titles, paths, icons, and corresponding descriptions for the cards
  const cardData = [
    {
      title: "Civil Services Exam",
      path: "/freeresources/FRAboutUPSCContent",
      icon: FaChalkboardTeacher, // Chalkboard teacher icon
      description: "Explore expert guidance and curated content to crack the UPSC exam."
    },
    {
      title: "Eligibility",
      path: "/freeresources/FRAboutUPSCEligibility",
      icon: FaUserGraduate, // Graduate icon
      description: "Understand the detailed eligibility criteria to appear for the UPSC exam."
    },
  ];

  return (
    <div className="section1-container">
      <div className="card-container" style={gridStyle}>
        {cardData.map((card, index) => (
          <Link to={card.path} key={index} className="card">
            <h4 style={{ fontSize: "18px" }}>
              <card.icon style={{ marginRight: '10px', color: "#DD5D59" }} /> {card.title} {/* Use the icon component */}
            </h4>
            <h6 className="cardh6" style={{ fontWeight: "600", fontSize: "17px" }}>{card.description}</h6> {/* Display the description */}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default AboutUPSC;
