import { Logo } from "assets/images";
import { errorResponse, failResponse } from "config/config";
import useButtonLoader from "hooks/useButtonLoader";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import { updateUserProfile } from "services/dashboard";
import { paytmChecksum, svICICchecksum, svPaytmchecksum } from "services/payment";
import PaymentPopup from "../../../pages/course-subscription/section/PaymentPopup";
import axios from "axios";



const PaymentLinkPayment = ({
  isEditable,
  handleValidation,
  userData,
  courseId,
  courseID1,
  BtransactionID,
  AdmissionID,
  studentID,
  billAmount,
  courseDetails,
  buttonRef,
  setButtonLoading,
  amtotal,
  studentid
}) => {
  const [terms, setTerms] = useState(false);
  const [terms1, setTerms1] = useState(false);
  const [terms2, setTerms2] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [isPaymentPopupVisible, setPaymentPopupVisible] = useState(false);
  // course purchase handler
  // course purchase handler
 // course purchase handler
  // course purchase handler
  const coursePurchaseHandler = async () => {
    const mobile = userData.Mobile
    const emobile = userData.EmergencyContactNo
    if (userData.mobileVerified === false) {
      if (mobile) {
        const numericValue = mobile.replace(/\D/g, "");
        if (numericValue.length < 10) {
          toast.error("Mobile number must be 10 digits");
        }
      }
      if (emobile) {
        const numericValue = emobile.replace(/\D/g, "");
        if (numericValue.length < 10) {
          toast.error("Mobile number must be 10 digits");
        }
      }
    }
    if (!terms) {
      toast.error("Please check terms & condition");
    }
    else if (!terms2) {
      toast.error("Please check compatible mobile courses");
    }
     else {
      try {
        //this function will run if user 's profile isn't updated
        if (
          isEditable.profile ||
          isEditable.idProof ||
          isEditable.idphoto ||
          isEditable.addressProof
        ) {
          if (handleValidation()) {
            // setButtonLoading(true);
            const {
              IDPhoto,
              IDProof,
              ProfileImage,
              UserID,
              addressProof,
              ...rest
            } = userData;
            const res = await updateUserProfile(rest);
            if (res.code === 200) {
              setButtonLoading(true);
              if (courseDetails.PaymentGateway === "Paytm") {
                setPaymentPopupVisible(true);
              }
              else {
                makePayment(billAmount)
              }

              //   setButtonLoading(true);
              // const res = await purchaseBillingCourse({
              //   courseID: courseId,
              //   ammount: courseDetails.total,
              // });
              // if (res.code === 200) {
              //   toast.success("thank you for purchase");
              //   navigate("/thank-you");
              //   setButtonLoading(false);
              // } else {
              //   failResponse(res);
              //   setButtonLoading(false);
              // }
            } else {
              failResponse(res);
              setButtonLoading(false);
            }
          } else {
            toast.error(
              "Please fill all fields with id proof, address proof and id photo"
            );
          }
        }

        // this function will run if user 's profile already updated
        if (
          !isEditable.profile &&
          !isEditable.idProof &&
          !isEditable.idphoto &&
          !isEditable.addressProof
        ) {
          setButtonLoading(true);
          if (courseDetails.PaymentGateway === "Paytm") {
            setPaymentPopupVisible(true);
          }
          else {
            makePayment(billAmount)
          }
          // const res = await purchaseBillingCourse({
          //   courseID: courseId,
          //   ammount: courseDetails.total,
          // });
          // if (res.code === 200) {
          //   toast.success("thank you for purchase");
          //   navigate("/thank-you");
          //   setButtonLoading(false);
          // } else {
          //   failResponse(res);
          //   setButtonLoading(false);
          // }
        }
      } catch (err) {
        errorResponse(err);
      }
    }
  };



  const makePayment = async (total) => {
    try {
      console.log("courseid", courseId);
      console.log("amtotak", amtotal);
      console.log("studentid", studentid);
      
      // alert(courseDetails.paymentgateway)
      const response = await axios.get(
        `https://nodesrapi.shubhraranjan.com/api/v1/client/plink-icici-checksum?courseID=${courseId}&ammount=${amtotal}&studentid=${studentid}`,
        {
          headers: {
            API_KEY: "qaw8AxBFDXzJLbhMJyTOmkoFGLlagVUY",
          },
        }
      );
  
      const paytmData = response.data.data; // Destructure the data from the response
      // alert(response.data.data.pgurl)
  
      // if (paytmData.data.code === 200) {
      //   if (paytmData.pgurl) {
      // alert("outside")
      if (paytmData) {
        // alert("inside if")
        
        let pgurl = response.data.data.pgurl;
        window.location.href = pgurl;
      }else if(response.data.code === 100){
        toast.error(response.data.message);
        setButtonLoading(false);
      }
      else{
        // alert("inside else")
        toast.error(paytmData.message);
        setButtonLoading(false);
      }
      //   } else {
      //     onScriptLoad({
      //       ...paytmData,
      //     });
      //   }
      // } else {
      //   toast.error(paytmData.message);
      //   setButtonLoading(false);
      // }
    } catch (err) {
      errorResponse(err);
      setButtonLoading(false);
    }
  };
  
  const makePaymentbysvICICchecksum = async (total) => {
    try {
      const response = await axios.get(
        `https://nodesrapi.shubhraranjan.com/api/v1/client/plink-sv-icici-checksum?courseID=${courseId}&ammount=${amtotal}&studentid=${studentid}`,
        {
          headers: {
            API_KEY: "qaw8AxBFDXzJLbhMJyTOmkoFGLlagVUY",
          },
        }
      );
      const paytmData = response.data.data; // Destructure the data from the response
  
      // Check if paytmData exists and handle accordingly
      if (paytmData) {
        if (paytmData.pgurl) {
          const pgurl = paytmData.pgurl;
          window.location.href = pgurl; // Redirect to the payment gateway URL
        } else {
          onScriptLoad({
            ...paytmData,
          });
        }
      }
      else if(response.data.code === 100){
        toast.error(response.data.message);
        setButtonLoading(false);
      } else {
        toast.error("Payment failed. No URL found.");
        setButtonLoading(false); // Stop the button loading state
      }
    } catch (err) {
      errorResponse(err); // Handle the error
      setButtonLoading(false); // Stop the button loading state
    }
  };
  
 const makePaymentbysvPaytmchecksum = async (total) => {
    try {
      const response = await axios.get(
        `https://nodesrapi.shubhraranjan.com/api/v1/client/plink-sv-paytm-checksum?courseID=${courseId}&ammount=${amtotal}&studentid=${studentid}`,
        {
          headers: {
            API_KEY: "qaw8AxBFDXzJLbhMJyTOmkoFGLlagVUY",
          },
        }
      );
      

  
      const paytmData = response.data;
  
      // Check if paytmData exists and handle accordingly
      if (paytmData) {
        if (paytmData.pgurl) {
          const pgurl = paytmData.pgurl;
          window.location.href = pgurl; // Redirect to the payment gateway URL
        } else {
          console.log({
            MID: paytmData.data.MID,
            WEBSITE: paytmData.data.WEBSITE,
            CHANNEL_ID: paytmData.data.CHANNEL_ID,
            INDUSTRY_TYPE_ID: paytmData.data.INDUSTRY_TYPE_ID,
            CUST_ID: paytmData.data.CUST_ID,
            TXN_AMOUNT: paytmData.data.TXN_AMOUNT,
            ORDER_ID: paytmData.data.ORDER_ID,
            CALLBACK_URL: paytmData.data.CALLBACK_URL,
            txnToken: paytmData.data.txnToken,
            CHECKSUMHASH: paytmData.data.CHECKSUMHASH,
          });
          
          onScriptLoad({
            MID: paytmData.data.MID,
            WEBSITE: paytmData.data.WEBSITE,
            CHANNEL_ID: paytmData.data.CHANNEL_ID,
            INDUSTRY_TYPE_ID: paytmData.data.INDUSTRY_TYPE_ID,
            CUST_ID: paytmData.data.CUST_ID,
            TXN_AMOUNT: paytmData.data.TXN_AMOUNT,
            ORDER_ID: paytmData.data.ORDER_ID,
            CALLBACK_URL: paytmData.data.CALLBACK_URL,
            txnToken: paytmData.data.txnToken,
            CHECKSUMHASH: paytmData.data.CHECKSUMHASH,
          });
        }
      }else if(response.data.code === 100){
        toast.error(response.data.message);
        setButtonLoading(false);
      } else {
        toast.error("Payment failed. No URL found.");
        setButtonLoading(false); // Stop the button loading state
      }
    } catch (err) {
      errorResponse(err); // Handle the error
      setButtonLoading(false); // Stop the button loading state
    }
  };
 
 
 
   const handleClosePopup = () => {
     setPaymentPopupVisible(false);
     setButtonLoading(false); // Stop the button loading when popup is closed
   };
 
   const handlePaymentMethodSelection = (method) => {
     setPaymentPopupVisible(false);
     if (method === 'Paytm') {
       makePaymentbysvPaytmchecksum(courseDetails.total);
     }
     else if (method === 'ICICI') {
       makePaymentbysvICICchecksum(courseDetails.total);
     }
     else {
       makePayment(courseDetails.total);
     }
   };
 
   const onScriptLoad = async (paymentData) => {
     var config = {
       root: "",
       flow: "DEFAULT",
       merchant: {
         name: paymentData.website,
         logo: Logo,
       },
       style: {
         headerBackgroundColor: "#DD5D59",
         headerColor: "#fff",
         bodyColor: "#000",
         themeBackgroundColor: "#DD5D59",
         themeColor: "#fff",
       },
       data: {
         orderId: paymentData.ORDER_ID,
         token: paymentData.txnToken,
         tokenType: "TXN_TOKEN",
         amount: paymentData.TXN_AMOUNT,
         userDetail: {
           mobileNumber: paymentData.MOBILE_NO,
         },
       },
       handler: {
         notifyMerchant: function (eventName, data) { },
       },
     };
 
     if (window.Paytm && window.Paytm.CheckoutJS) {
       
       window.Paytm.CheckoutJS.init(config)
         .then(function onSuccess() {
           window.Paytm.CheckoutJS.invoke();
           setButtonLoading(false);
         })
         .catch(function onError(error) {
           //toast.error(error);
           toast.error("Something wents wrong");
           setButtonLoading(false);
         });
     }
   };

  return (
    <>
      <label className="check_box_wrap">
        <input
          type="checkbox"
          checked={terms}
          onChange={({ target }) => setTerms(target.checked)}
        />
        By checking this you are agree to our
        <span className="link" onClick={() => setShowModal(true)}>
          Terms & Conditions
        </span>
      </label>
      <label className="check_box_wrap">
        <input
          type="checkbox"
          checked={terms2}
          onChange={({ target }) => setTerms2(target.checked)}
        />
        Compatibility check for Mobile Courses
        <span className="link" onClick={() => setShowModal1(true)}>
          click here
        </span>
      </label>
      <h6>For Technical Issues</h6>
      <h6>If you encounter any technical issues, please contact us via email or phone</h6>
      <ul>
        <li>
          <b>Email : </b><Link to={"admin@shubhraranjan.com"}>admin@shubhraranjan.com</Link>
        </li>
        <li>
          <b>Mobile : </b><Link>+91 9810212719</Link>
        </li>
      </ul>
      <div className="button_wrap">
        <button
          type="button"
          className="dashboard_button fill"
          onClick={coursePurchaseHandler}
          ref={buttonRef}
        >
          Proceed to payment
        </button>
      </div>

      {/* Terms & condition modal */}

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        dialogClassName="modal-90w"
        aria-labelledby="terms&condition"
        className="terms_condition_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="terms&condition">Terms & condition</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="terms_condition">
            <div className="terms_condition_content">
              <ol>
                <li>
                  Fees is payable in advance for all the courses. Fees once paid
                  is not refundable.
                </li>
                <li>
                  The Course Duration of PSIR Optional is 6 Months, of Sociology
                  Optional is 4.5 Months, of Geography Optional is 5 Months, of
                  Anthropology Optional is 5 Months and that of General Studies
                  Foundation batch is 13 Months. This duration is liable to
                  change due to unforeseen circumstances
                </li>
                <li>
                  Samsung Tablet (WiFi+LTE, 32GB) shall be provided to the
                  subscribers for the Tablet courses.
                </li>
                <li>
                  The Tablet will be locked for the duration of the subscribed
                  course and will only be unlocked after the completion of the
                  Course (i.e. after expiry of the subscribed course) for normal
                  usage.
                </li>
                <li>
                  A 4G SIM card or WiFi connection with minimum 10 MBPS Internet
                  speed is required to view the tablet courses
                </li>
                <li>
                  The Political Science & IR Optional Lecture of English Medium
                  will be available for access for 48 hours from the time of
                  upload on the Tablet with a maximum of 2 views. For example,
                  lecture of 20 May 2022 11 AM will be available for access till
                  11 AM of May 22, 2022.
                </li>
                <li>
                  The Political Science & IR Optional course is designed to
                  allow maximum of 650 hours of viewing for 130 lectures. Each
                  lecture has a maximum duration of 2.5 hours.
                </li>
                <li>
                  8. The Political Science & IR Optional Lecture of Hindi Medium
                  will be available for access for maximum of 168 hours from the
                  time of upload on the Tablet with a maximum of 2 views. For
                  example, lecture of 20 May 2022 6 PM will be available for
                  access from 20 May to 26 May, 2022 till 6 PM.
                </li>
                <li>
                  The Political Science & IR Optional Lecture of Hindi medium
                  course is designed to allow maximum of 700 hours of viewing
                  for 80 lectures.
                </li>
                <li>
                  For Political Science & IR Optional course of English Medium,
                  there will be Geo-fencing of 2 Km from Patel Nagar, Delhi. So
                  the tablet will not work in the 2 Kms radius of the center
                  point (Latitude and Longitude 28.64318, 77.17236)
                </li>
                <li>
                  The Geography Optional Lecture will be available for access
                  for maximum of 2 days on the Tablet with a maximum of 2 views.
                  For example, Lecture of 20 May 2022 will be available for
                  access on 20 May 2022, 21 May 2022.
                </li>
                <li>
                  The Geography Optional course is designed to allow maximum of
                  650 hours of viewing for 130 lectures. Each lecture has a
                  maximum duration of 2.5 hours
                </li>
                <li>
                  The Anthropology Optional Lecture will be available for access
                  for maximum of 2 days on the Tablet with a maximum of 2 views.
                  For example, Lecture of 20 May 2022 will be available for
                  access on 20 May 2022 & 21 May 2022.
                </li>
                <li>
                  The Anthropology Optional course is designed to allow maximum
                  of 400 hours of viewing for 100 lectures. Each lecture has a
                  maximum duration of 2.5 hours
                </li>
                <li>
                  The Sociology Optional Lecture will be available for access
                  for maximum of 4 days on the Tablet with a maximum of 2 views.
                  For example, Lecture of 20 May 2022 will be available for
                  access on 20 May, 21 May, 22 May & 23 May 2022.
                </li>
                <li>
                  The Sociology Optional course is designed to allow maximum of
                  400 hours of viewing for 100 lectures. Each lecture has a
                  maximum duration of 2.5 hours.
                </li>
                <li>
                  All General Studies Lectures shall be available for access
                  till last date of UPSC Mains Exam of Target Year.
                </li>
                <li>
                  The General Studies Tablet course is designed to allow maximum
                  of 2250 hours of viewing for 300 lectures. Each lecture has a
                  maximum duration of 2.5 hours.
                </li>
                <li>
                  There is no provision to Top Up viewing time for tablet
                  courses.
                </li>
                <li>
                  The subscriber will be responsible for any damages or loss
                  after the handover. In such cases the subscriber need to re-
                  apply for tablet again and the same shall be provided by the
                  institute on payment basis.
                </li>
                <li>
                  The tablet shall be delivered to your Present Address as
                  stated at the time of admission. In case you plan to seek
                  delivery at our office address do inform us by mailing us at
                  support@shubhraranjan.com. The subscriber need to ensure that
                  the tablet is delivered in good condition at the time of
                  receipt of the tablet from the courier company. In case of any
                  physical damage observed during transit the same shall be
                  reported by email with the image of defective tablet within 4
                  hours of the time of receipt.
                </li>
                <li>
                  The tablet is under one year warranty offered by the company.
                  The subscriber needs to directly contact the nearest Samsung
                  service center in case of any hardware issues. The institute
                  shall not be held liable for any hardware issues.
                </li>
                <li>
                  In case of any software issues the same shall be resolved by
                  the software support team of the institute on need basis.
                </li>
                <li>
                  In case it is found that the tablet has been misused for
                  piracy purposes the tablet will be locked immediately and
                  course fee will be forfeited. Appropriate legal action may
                  also be initiated against the subscriber in such cases.
                </li>
                <li>
                  In case of any indiscipline or misconduct, the subscriber's
                  admission to the course shall be cancelled with immediate
                  effect. No fees will be refundable in such case.
                </li>
                <li>
                  All disputes shall be subject to jurisdiction of Delhi Courts
                  only.
                </li>
                <li>
                  Tablet is not transferable and can be used only in India.
                </li>
              </ol>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showModal1}
        onHide={() => setShowModal1(false)}
        dialogClassName="modal-90w"
        aria-labelledby="terms&condition"
        className="terms_condition_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="terms&condition">Compatibility check for  Mobile courses</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="terms_condition">
            <div className="terms_condition_content">
              <h6>1. For Android App</h6>
              <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
                <li>Android Version should be 12 or higher</li>
                <li>OS Version/One UI Version should be 6.0 or higher</li>
              </ul><br />

              <h6>2. For iOS App</h6>
              <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
                <li>Not compatible with iPhone Mini</li>
                <li>iOS Version should be 17 or higher</li>
                <li>iPhone 10 or newer models</li>
              </ul><br />

              <h6>How to Check Compatibility with Your Android or Apple Phone</h6>
              <ul>
                <li>1) Register on the <a href="https://shubhraranjan.com">Shubhraranjan.com</a> website.</li>
                <li>2) Download the Shubhra Ranjan App from the Play Store or App Store as appropriate.</li>
                <li>3) Login with the same credentials that were used for registration.</li>
                <li>4) After login, go to the demo course and play some lectures. Check the overall layout to ensure texts are visible on your phone.</li>
              </ul><br />

              <h6 style={{ paddingBottom: "2px" }}>Note:</h6>
              <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
                <li>Purchase mobile courses only if you are able to see the demo course appropriately in the mobile app.</li>
                <li>After purchasing a course, fees will not be refunded if the course cannot be viewed appropriately on the mobile app.</li>
                <li>If you are using Android, Tablet or IOS, check compatibility on Tablet as well in the similar way.</li>
                <li>Videos doesn’t play on  Xiaomi pad 6 devices.</li>
              </ul>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <PaymentPopup
        visible={isPaymentPopupVisible}
        onClose={handleClosePopup}
        onSelectPaymentMethod={handlePaymentMethodSelection}
      />
    </>
  );
};

export default PaymentLinkPayment;
