import { Input } from "components/Form";
import CommonModal from "components/modal-box/CommonModal";
import { errorResponse, failResponse } from "config/config";
import { validateEmail } from "config/utils";
import useButtonLoader from "hooks/useButtonLoader";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { BsArrowRight } from "react-icons/bs";
import { SubmitCourseEnquiry } from "services/courseenquiry";

export default function CourseEnquiryRegisterModal({ flag, setFlag }) {
  const [data, setData] = useState({});
  const [errorField, setErrorField] = useState({});

  const [submitButtonRef, setSubmitLoading] = useButtonLoader();
  // onchange handler
  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  //*********************Handel Validation********************************** */
  const handleValidation = () => {
    let errorField = {};
    let formIsValid = true;

    if (!data.name) {
      errorField["name"] = "Please enter full name";
      formIsValid = false;
    }

    if (!data.email) {
      errorField["email"] = "Please enter email";
      formIsValid = false;
    } else if (!validateEmail(data.email)) {
      errorField["email2"] = "Please enter valid email";
      formIsValid = false;
      // setIsValidEmail(false);
    }

    if (!data.mobile) {
      errorField["mobile"] = "Please enter mobile No.";
      formIsValid = false;
    } else if (data.mobile.length < 10) {
      errorField["mobile2"] = "Mobile number must be 10 digit";
      formIsValid = false;
    }

    setErrorField(errorField);
    return formIsValid;
  };

  //   ***************************************** handle Course Enquiry *****************************************
  const handleCourseEnquiry = async (e) => {
    e.preventDefault();
    if (handleValidation()) {
      setSubmitLoading(true);
      try {
        const res = await SubmitCourseEnquiry({
          studentname: data.name,
          emailid: data.email,
          mobile: data.mobile,
        });
        if (res.code === 200) {
          toast.success(res.message);
          setFlag(false);
          setData({});
          setErrorField({});
          setSubmitLoading(false);
        } else {
          setFlag(false);
          failResponse(res);
          setData({});
          setErrorField({});
          setSubmitLoading(false);
        }
      } catch (err) {
        errorResponse(err);
        setSubmitLoading(false);
      }
    } else {
      toast.error("Please Fill All Fields");
    }
  };
  return (
    <>
      {/* ************************************************** Register Modal ************************************************** */}
      <CommonModal
        show={flag}
        handleClose={() => setFlag(false)}
        // className={"p-1 py-5 z-index-5"}
        className={"course-enquiry-modal"}
        title={"Register"}
      >
        <>
          <div className="form_field">
            <label className="label p-0">
              {"Name"}
              <span className="required mx-1"> *</span>
            </label>

            <Input
              type="text"
              name="name"
              value={data.name || ""}
              onChange={onChangeHandler}
              onKeyPress={(event) => {
                if (!/[a-zA-Z\s]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              placeholder="Enter full name"
              className={`input form-control ${
                errorField.name ? "error" : ""
              }`}
              maxLength={50}
              // label={"Name"}
              error={errorField.name}
              required
            />
          </div>

          <div className="form_field">
            <label className="label p-0">
              {"Email"}
              <span className="required mx-1"> *</span>
            </label>
            <Input
              type="text"
              name="email"
              value={data.email || ""}
              onChange={onChangeHandler}
              placeholder="Enter Email"
              className={`input form-control ${
                errorField.email2||errorField.email ? "error" : ""
              }`}
              maxLength={50}
              // label={"Email"}
              error={errorField.email}
              required
            />
            {errorField.email2 && (
              <div style={{ fontSize: "12px", color: "#DD5D59" }}>
                {errorField.email2}
              </div>
            )}
          </div>

          <div className="form_field p-2" style={{ textAlign: "left" }}>
            <label className="label p-0">
              {"Mobile"}
              <span className="required mx-1"> *</span>
            </label>
            <Input
              type="text"
              name="mobile"
              value={data.mobile || ""}
              onChange={({ target }) =>
                setData({
                  ...data,
                  mobile: target.value.replace(/\D/g, ""),
                })
              }
              placeholder="Enter your phone number"
             
              className={`input form-control ${
                errorField.mobile2||errorField.mobile ? "error" : ""
              }`}
              maxLength={10}
              // label={"Mobile"}
              error={errorField.mobile}
              required
            />
            {errorField.mobile2 && (
              <div style={{ fontSize: "12px", color: "#DD5D59" }}>
                {errorField.mobile2}
              </div>
            )}
          </div>
          <div
          className="button_wrap"
            // className={
            //   window.innerWidth > 768 ? "button_wrap p-3" : "button_wrap p-1"
            // }
            // style={
            //   window.innerWidth > 768
            //     ? {
            //         display: "flex",
            //         justifyContent: "center",
            //         gap: "20px",
            //         marginBottom: "0",
            //       }
            //     : {
            //         display: "flex",
            //         justifyContent: "center",
            //         gap: "20px",
            //         marginBottom: "50px",
            //       }
            // }
          >
            <button
              //   to="#"
              onClick={() => setFlag(false)}
              className="button"
              //   ref={submitButtonRef}
            >
              Cancel
            </button>
            <button
              //   to="#"
              onClick={handleCourseEnquiry}
              className="button"

              //   ref={submitButtonRef}
            >
              Submit
              <span className="icon">
                <BsArrowRight
                  style={
                    window.innerWidth > 768
                      ? { fontSize: "20px" }
                      : { fontSize: "14px" }
                  }
                />
              </span>
            </button>
          </div>
        </>{" "}
      </CommonModal>
    </>
  );
}
